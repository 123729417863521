import React, { Fragment, useEffect, useRef, useState } from "react";
import IconSvg from "../../modules/IconSvg";
import localizations from "../../localizations.json";
import {
  changeImage,
  docx,
  filePresent,
  locationIMG,
  paperPlaneSendIcon,
  uploadPreview,
  videoPlus,
  xlsx,
  zip,
} from "../../libs/images.js";
import Tippy from "@tippyjs/react";
import { tippyPrefs } from "../../modules/modulesConfig.js";
import { UseFetchPostAnonymos } from "../../hooks/fetchFunctions.js";
import { implementedVars } from "../../libs/implementedVars.js";
import { Box, Button, Tooltip } from "@mui/material";
import {
  Image,
  ImageOutlined,
  Send,
  StickyNote2Outlined,
  VideoCameraBackOutlined,
} from "@mui/icons-material";

function SingleTemplate({
  template,
  chatUser,
  isThisActive,
  setActiveTemplateFunction,
  whatsappTemplatesList,
  createParamsObject,
  handleSendTemplate,
  templateFileLink,
  setTemplateFileLink,
  setTemplateFileName,
  filters,
  templatesMenuRef,
  resetTemplate,
  templatesWithSend,
  noSendTemplateAction,
  noUpload,
  hebrewNamePopoverRef,
  setHebrewNamePopover,
}) {
  const [localization, setLocalization] = useState(
    localizations["he"].templatesMenu,
  );
  const paramNameByVariable = {
    [localizations["he"].implementedVariables["$contact.accountid"].name]: (
      chatUser,
    ) => chatUser.Id,
    [localizations["he"].implementedVariables["$contact.last_incoming_message"]
      .name]: (chatUser) => chatUser.IncomingLastMessage,
    [localizations["he"].implementedVariables["$contact.accountname"].name]: (
      chatUser,
    ) => chatUser.FullName,
    [localizations["he"].implementedVariables["$contact.phone"].name]: (
      chatUser,
    ) => chatUser.UserDisplayPhoneNumber,
    [localizations["he"].implementedVariables["$contact.contactd"].name]: (
      chatUser,
    ) => "",
    [localizations["he"].implementedVariables["$contact.firstName"].name]: (
      chatUser,
    ) => chatUser.FullName.split(" ")[0],
  };

  const languageIcons = {
    he: (
      <IconSvg
        iconString={`<svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.41932 1.46631C7.77269 1.46631 6.25767 2.0158 5.03369 2.93258H13.8046C12.581 2.0158 11.0659 1.46631 9.41932 1.46631Z" fill="white"/>
            <path d="M16.7508 8.79751C16.7508 7.14465 16.1972 5.62485 15.2746 4.39868H3.56419C2.64153 5.62485 2.08801 7.14465 2.08801 8.79751C2.08801 10.4504 2.64153 11.9702 3.56419 13.1963H15.2746C16.1972 11.9702 16.7508 10.4504 16.7508 8.79751Z" fill="white"/>
            <path d="M5.03369 14.6626C6.25766 15.5794 7.77233 16.1289 9.41932 16.1289C11.0663 16.1289 12.581 15.5794 13.805 14.6626H5.03369Z" fill="white"/>
            <path d="M15.2746 13.1963H3.56421C3.98246 13.7524 4.47697 14.2454 5.03378 14.6626H13.8047C14.3619 14.2454 14.856 13.7524 15.2746 13.1963Z" fill="#3F51B5"/>
            <path d="M13.805 2.93237H5.03378C4.47697 3.34953 3.98246 3.84256 3.56421 4.39865H15.2746C14.856 3.84256 14.3619 3.34953 13.805 2.93237Z" fill="#3F51B5"/>
            <path d="M6.85632 10.2638H8.56747L9.10302 11.1817L9.41937 11.7242L9.73572 11.1817L10.2713 10.2638H11.9824L11.6602 9.71249L11.1261 8.79754L11.6602 7.88258L11.9824 7.33126H10.2713L9.73572 6.41337L9.41937 5.87085L9.10302 6.41337L8.56747 7.33126H6.85632L7.17854 7.88258L7.71263 8.79754L7.17854 9.71249L6.85632 10.2638ZM9.41937 10.9969L8.99122 10.2638H9.84606L9.41937 10.9969ZM8.77788 9.89724L8.13638 8.79754L8.77788 7.69783H10.0609L10.7024 8.79754L10.0609 9.89724H8.77788ZM11.3439 9.89724H10.4846L10.9142 9.16117L11.3439 9.89724ZM11.3439 7.69783L10.9142 8.4339L10.4846 7.69783H11.3439ZM9.41937 6.59812L9.84753 7.33126H8.99269L9.41937 6.59812ZM7.49489 7.69783H8.35412L7.92451 8.4339L7.49489 7.69783ZM7.92451 9.16117L8.35412 9.89724H7.49489L7.92451 9.16117Z" fill="#3F51B5"/>
          </svg>
        `}
        size={{ width: 18, height: 18 }}
        color={""}
      />
    ),
    en_US: (
      <IconSvg
        iconString={`<svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.6217 2.03317V2.0031C10.9272 1.78494 10.1884 1.6665 9.42171 1.6665C5.37151 1.6665 2.08838 4.94964 2.08838 8.99984C2.08838 9.24734 2.10158 9.4919 2.12541 9.73317H11.6217V2.03317Z" fill="#3F51B5"/>
              <path d="M11.6217 4.2333H14.9877C14.6368 3.8241 14.2404 3.45707 13.8081 3.1333H11.6217V4.2333Z" fill="#ECEFF1"/>
              <path d="M16.718 9.7334H11.6217H2.12537C2.16277 10.1103 2.23647 10.4755 2.32887 10.8334H16.5148C16.6069 10.4755 16.6806 10.1103 16.718 9.7334Z" fill="#ECEFF1"/>
              <path d="M14.6544 14.1333H4.18896C4.6022 14.5546 5.06823 14.9213 5.5724 15.2333H13.2709C13.7751 14.9213 14.2411 14.5546 14.6544 14.1333Z" fill="#ECEFF1"/>
              <path d="M16.1408 11.9333H2.70251C2.87081 12.3183 3.07101 12.6861 3.30018 13.0333H15.5432C15.7723 12.6861 15.9722 12.3183 16.1408 11.9333Z" fill="#ECEFF1"/>
              <path d="M11.6217 6.43325H16.2842C16.1409 6.05045 15.9685 5.68269 15.7661 5.33325H11.6217V6.43325Z" fill="#ECEFF1"/>
              <path d="M11.6217 8.6332H16.7367C16.718 8.2592 16.6806 7.89034 16.608 7.5332H11.6217V8.6332Z" fill="#ECEFF1"/>
              <path d="M11.6217 3.1332H13.8085C13.1767 2.6602 12.4698 2.284 11.7035 2.0332H11.6217V3.1332Z" fill="#F44336"/>
              <path d="M11.6217 5.3334H15.7661C15.5399 4.94253 15.2799 4.5744 14.9881 4.2334H11.6217V5.3334Z" fill="#F44336"/>
              <path d="M11.6217 7.53335H16.6076C16.5306 7.15422 16.417 6.78902 16.2839 6.43335H11.6217V7.53335Z" fill="#F44336"/>
              <path d="M11.6217 9.7333H16.718C16.7418 9.49203 16.755 9.24747 16.755 8.99997C16.755 8.87603 16.7426 8.7554 16.7367 8.6333H11.6217V9.7333Z" fill="#F44336"/>
              <path d="M16.5145 10.8333H2.32886C2.42639 11.212 2.54849 11.5809 2.70286 11.9333H16.1408C16.2948 11.5809 16.4169 11.212 16.5145 10.8333Z" fill="#F44336"/>
              <path d="M15.5432 13.0332H3.30017C3.56087 13.4285 3.8586 13.7966 4.18897 14.1332H14.6547C14.9847 13.7966 15.2825 13.4285 15.5432 13.0332Z" fill="#F44336"/>
              <path d="M5.57239 15.2334C6.69219 15.9264 8.00779 16.3334 9.42165 16.3334C10.8355 16.3334 12.1511 15.9264 13.2709 15.2334H5.57239Z" fill="#F44336"/>
              <path d="M4.38008 4.96655L4.55058 5.32882L4.93008 5.38602L4.65508 5.66835L4.72108 6.06655L4.38008 5.87882L4.03908 6.06655L4.10508 5.66835L3.83008 5.38602L4.20958 5.32882L4.38008 4.96655Z" fill="white"/>
              <path d="M5.84675 4.96655L6.01725 5.32882L6.39675 5.38602L6.12175 5.66835L6.18775 6.06655L5.84675 5.87882L5.50575 6.06655L5.57175 5.66835L5.29675 5.38602L5.67625 5.32882L5.84675 4.96655Z" fill="white"/>
              <path d="M2.91341 5.87886L3.25441 6.0666L3.18841 5.6684L3.46341 5.38606L3.08391 5.32886L3.08244 5.3252C2.96144 5.53346 2.85034 5.74796 2.74988 5.9687L2.91341 5.87886Z" fill="white"/>
              <path d="M7.31343 4.96655L7.48393 5.32882L7.86343 5.38602L7.58843 5.66835L7.65443 6.06655L7.31343 5.87882L6.97243 6.06655L7.03843 5.66835L6.76343 5.38602L7.14293 5.32882L7.31343 4.96655Z" fill="white"/>
              <path d="M8.77998 4.96655L8.95048 5.32882L9.32998 5.38602L9.05498 5.66835L9.12098 6.06655L8.77998 5.87882L8.43898 6.06655L8.50498 5.66835L8.22998 5.38602L8.60948 5.32882L8.77998 4.96655Z" fill="white"/>
              <path d="M10.155 4.96655L10.3255 5.32882L10.705 5.38602L10.43 5.66835L10.496 6.06655L10.155 5.87882L9.81398 6.06655L9.87998 5.66835L9.60498 5.38602L9.98448 5.32882L10.155 4.96655Z" fill="white"/>
              <path d="M4.38008 7.8999L4.55058 8.26217L4.93008 8.31937L4.65508 8.6017L4.72108 8.9999L4.38008 8.81217L4.03908 8.9999L4.10508 8.6017L3.83008 8.31937L4.20958 8.26217L4.38008 7.8999Z" fill="white"/>
              <path d="M5.84675 7.8999L6.01725 8.26217L6.39675 8.31937L6.12175 8.6017L6.18775 8.9999L5.84675 8.81217L5.50575 8.9999L5.57175 8.6017L5.29675 8.31937L5.67625 8.26217L5.84675 7.8999Z" fill="white"/>
              <path d="M7.31343 7.8999L7.48393 8.26217L7.86343 8.31937L7.58843 8.6017L7.65443 8.9999L7.31343 8.81217L6.97243 8.9999L7.03843 8.6017L6.76343 8.31937L7.14293 8.26217L7.31343 7.8999Z" fill="white"/>
              <path d="M2.9134 7.8999L3.0839 8.26217L3.4634 8.31937L3.1884 8.6017L3.2544 8.9999L2.9134 8.81217L2.5724 8.9999L2.6384 8.6017L2.3634 8.31937L2.7429 8.26217L2.9134 7.8999Z" fill="white"/>
              <path d="M8.77998 7.8999L8.95048 8.26217L9.32998 8.31937L9.05498 8.6017L9.12098 8.9999L8.77998 8.81217L8.43898 8.9999L8.50498 8.6017L8.22998 8.31937L8.60948 8.26217L8.77998 7.8999Z" fill="white"/>
              <path d="M10.155 7.8999L10.3255 8.26217L10.705 8.31937L10.43 8.6017L10.496 8.9999L10.155 8.81217L9.81398 8.9999L9.87998 8.6017L9.60498 8.31937L9.98448 8.26217L10.155 7.8999Z" fill="white"/>
              <path d="M4.74666 6.43335L4.91716 6.79562L5.29666 6.85282L5.02166 7.13515L5.08766 7.53335L4.74666 7.34562L4.40566 7.53335L4.47166 7.13515L4.19666 6.85282L4.57616 6.79562L4.74666 6.43335Z" fill="white"/>
              <path d="M6.21333 6.43335L6.38383 6.79562L6.76333 6.85282L6.48833 7.13515L6.55433 7.53335L6.21333 7.34562L5.87233 7.53335L5.93833 7.13515L5.66333 6.85282L6.04283 6.79562L6.21333 6.43335Z" fill="white"/>
              <path d="M3.27998 6.43335L3.45048 6.79562L3.82998 6.85282L3.55498 7.13515L3.62098 7.53335L3.27998 7.34562L2.93898 7.53335L3.00498 7.13515L2.72998 6.85282L3.10948 6.79562L3.27998 6.43335Z" fill="white"/>
              <path d="M7.68 6.43335L7.8505 6.79562L8.23 6.85282L7.955 7.13515L8.02101 7.53335L7.68 7.34562L7.339 7.53335L7.405 7.13515L7.13 6.85282L7.5095 6.79562L7.68 6.43335Z" fill="white"/>
              <path d="M9.14668 6.43335L9.31718 6.79562L9.69668 6.85282L9.42168 7.13515L9.48768 7.53335L9.14668 7.34562L8.80568 7.53335L8.87168 7.13515L8.59668 6.85282L8.97618 6.79562L9.14668 6.43335Z" fill="white"/>
              <path d="M10.5217 6.43335L10.6922 6.79562L11.0717 6.85282L10.7967 7.13515L10.8627 7.53335L10.5217 7.34562L10.1807 7.53335L10.2467 7.13515L9.97168 6.85282L10.3512 6.79562L10.5217 6.43335Z" fill="white"/>
              <path d="M4.74666 3.5L4.91716 3.86227L5.29666 3.91947L5.02166 4.2018L5.08766 4.6L4.74666 4.41227L4.40566 4.6L4.47166 4.2018L4.19666 3.91947L4.57616 3.86227L4.74666 3.5Z" fill="white"/>
              <path d="M6.21333 3.5L6.38383 3.86227L6.76333 3.91947L6.48833 4.2018L6.55433 4.6L6.21333 4.41227L5.87233 4.6L5.93833 4.2018L5.66333 3.91947L6.04283 3.86227L6.21333 3.5Z" fill="white"/>
              <path d="M7.68 3.5L7.8505 3.86227L8.23 3.91947L7.955 4.2018L8.02101 4.6L7.68 4.41227L7.339 4.6L7.405 4.2018L7.13 3.91947L7.5095 3.86227L7.68 3.5Z" fill="white"/>
              <path d="M9.14668 3.5L9.31718 3.86227L9.69668 3.91947L9.42168 4.2018L9.48768 4.6L9.14668 4.41227L8.80568 4.6L8.87168 4.2018L8.59668 3.91947L8.97618 3.86227L9.14668 3.5Z" fill="white"/>
              <path d="M10.5217 3.5L10.6922 3.86227L11.0717 3.91947L10.7967 4.2018L10.8627 4.6L10.5217 4.41227L10.1807 4.6L10.2467 4.2018L9.97168 3.91947L10.3512 3.86227L10.5217 3.5Z" fill="white"/>
              <path d="M6.18774 3.13333L6.12174 2.73513L6.39674 2.4528L6.1947 2.42236C5.97874 2.52833 5.76937 2.64456 5.56587 2.7707L5.50574 3.13333L5.84674 2.9456L6.18774 3.13333Z" fill="white"/>
              <path d="M7.31343 2.0332L7.48393 2.39547L7.86343 2.45267L7.58843 2.735L7.65443 3.1332L7.31343 2.94547L6.97243 3.1332L7.03843 2.735L6.76343 2.45267L7.14293 2.39547L7.31343 2.0332Z" fill="white"/>
              <path d="M8.77998 2.0332L8.95048 2.39547L9.32998 2.45267L9.05498 2.735L9.12098 3.1332L8.77998 2.94547L8.43898 3.1332L8.50498 2.735L8.22998 2.45267L8.60948 2.39547L8.77998 2.0332Z" fill="white"/>
              <path d="M10.155 2.0332L10.3255 2.39547L10.705 2.45304L10.43 2.73537L10.496 3.1332L10.155 2.94547L9.81398 3.1332L9.87998 2.735L9.60498 2.45267L9.98448 2.39547L10.155 2.0332Z" fill="white"/>
            </svg>
        `}
        size={{ width: 18, height: 18 }}
        color={""}
      />
    ),
  };
  const templatePreviewsByFileType = {
    pdf: uploadPreview,
    docx: docx,
    xlsx: xlsx,
    zip: zip,
  };
  const varsRegular = /{{(\d+)}}/g;

  const [hebrewName, setHebrewName] = useState(null);
  // const [varsData, setVarsData] = useState(null);
  const varsData = template.components;
  const [templateHeader, setTemplateHeader] = useState(null);
  const [isVars, setIsVars] = useState({
    haveVars: false,
    count: 0,
  });
  const [showImplementedVars, setShowImplementedVars] = useState({
    show: false,
    id: null,
  });

  const hebrewNameBtnRef = useRef(null);

  const toggleImplementedVars = (id) => {
    const prevValue = showImplementedVars.show;
    if (prevValue && id === showImplementedVars.id) {
      setShowImplementedVars({
        show: false,
        id: null,
      });
    } else {
      setShowImplementedVars({
        show: true,
        id: id,
      });
    }
  };
  const placeVarToInput = (id, value) => {
    const targetInput = templatesMenuRef.current.querySelector(
      `#param-input-${id}`,
    );

    targetInput.value = value;
    toggleImplementedVars(id);
    if (templatesWithSend) {
      createParamsObject();
    } else {
      noSendTemplateAction();
    }
  };

  async function handleImgUploadTemplate(e) {
    try {
      const file = e.target.files?.[0];
      createParamsObject();
      if (!file) {
        console.error("No file selected");
      }

      // if (!file.type.startsWith("image/")) {
      //   console.error("Only image files are allowed");
      // }

      const formData = new FormData();
      if (file.type.startsWith("image/")) {
        formData.append("image", file);
        const response = await UseFetchPostAnonymos(
          "/api/services/GetImageLink",
          formData,
          "config",
        );
        const { data } = response;
        if (data.result == true) {
          const fileType = "image";
          setTemplateFileLink(data?.link);
          setTemplateFileName(file.name);
          if (!templatesWithSend) noSendTemplateAction(data?.link);
          // console.log('handleImgUploadTemplate: image', messageConfig.templateFileLink)
          // renderTemplateFile(fileType);
        }
      } else if (file.type.startsWith("application/")) {
        formData.append("file", file);
        const response = await UseFetchPostAnonymos(
          "/api/whatsapp/SaveFile",
          formData,
          "config",
        );
        const { data } = response;
        console.log("data: ", data);
        if (data.result == true) {
          const fileType = getFileFormatFromURL(data.link);
          setTemplateFileLink(data?.link);
          setTemplateFileName(file.name);
          if (!templatesWithSend) noSendTemplateAction(data?.link);
          // renderTemplateFile(fileType);
        }
        console.log("handleImgUplaoadTemplate: formData", formData);
      } else if (file.type.startsWith("video/")) {
        formData.append("file", file);
        const response = await UseFetchPostAnonymos(
          "/api/whatsapp/SaveFile",
          formData,
          "config",
        );
        const { data } = response;
        console.log("data: ", data);
        if (data.result == true) {
          const fileType = "video";
          setTemplateFileLink(data?.link);
          setTemplateFileName(file.name);
          if (!templatesWithSend) noSendTemplateAction(data?.link);
          // renderTemplateFile(fileType);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      // e.target.value = null;
    }
  }
  function getFileFormatFromURL(url) {
    const fileExtension = url.split(".").pop();
    return fileExtension.toLowerCase();
  }

  const handleButtonHebrewNameClick = (e) => {
    const button = hebrewNameBtnRef.current;
    const top = button.offsetTop;
    const left = button.offsetLeft;

    console.log(button);
    console.log(left);

    setHebrewNamePopover({
      open: true,
      position: {
        top: top + 30,
        left: left,
      },
      template: template,
      target: e.currentTarget,
    });
  };

  // ----- Functions to set this template data ------

  const getTemplateHebrewName = () => {
    const hebrewTemplateName = whatsappTemplatesList?.some(
      (temp) => template.id === temp.id,
    )
      ? whatsappTemplatesList.find((temp) => template.id === temp.id).hebrewName
      : null;
    setHebrewName(hebrewTemplateName);
  };
  const getTemplateHeader = () => {
    const findTemplateHeader = template?.components?.some(
      (item) => item.type === "HEADER",
    )
      ? template?.components?.find((item) => item.type === "HEADER")
      : null;
    setTemplateHeader(findTemplateHeader);
  };
  const getTemplateVars = () => {
    const totalCount = template.components?.reduce((count, component) => {
      const headerLocationMatch =
        component?.type === "HEADER" && component.format === "LOCATION" ? 4 : 0;

      const urlMatches =
        component?.buttons?.reduce((btnCount, btn) => {
          if (btn.type === "URL") {
            const urlMatchCount = (btn.url?.match(/{{(\d+)}}/g) || []).length;
            return btnCount + urlMatchCount;
          }
          return btnCount;
        }, 0) || 0;

      const textMatches = (component?.text?.match(/{{(\d+)}}/g) || []).length;

      return count + headerLocationMatch + urlMatches + textMatches;
    }, 0);

    setIsVars({
      haveVars: totalCount > 0,
      count: totalCount,
    });
  };

  useEffect(() => {
    if (template) {
      getTemplateHebrewName();
      // setVarsData(template.components);
      getTemplateHeader();
      getTemplateVars();
    }
  }, [template]);

  return (
    <div className="single-funner-template">
      <div
        className={`single-funner-template__content ${
          isThisActive ? "single-funner-template__content--active" : ""
        }`}
        onClick={(e) =>
          !isThisActive &&
          !hebrewNameBtnRef?.current?.contains(e.target) &&
          !hebrewNamePopoverRef?.current?.contains(e.target)
            ? setActiveTemplateFunction(template)
            : null
        }
      >
        <div className="single-funner-template__header">
          <div className={`single-funner-template__name-wrapper`}>
            {hebrewName ? (
              <Tippy
                content={
                  <p>
                    {filters.isHebrew
                      ? template.name
                      : hebrewName.HebrewTemaplateName}
                  </p>
                }
                {...tippyPrefs}
              >
                <div className="single-funner-template__name">
                  {hebrewName
                    ? filters.isHebrew
                      ? hebrewName.HebrewTemaplateName
                      : template.name
                    : template.name}
                </div>
              </Tippy>
            ) : (
              <>
                <div className="single-funner-template__name">
                  {hebrewName
                    ? filters.isHebrew
                      ? hebrewName.HebrewTemaplateName
                      : template.name
                    : template.name}
                </div>
                <div
                  className="single-funner-template__icon funner-icon"
                  style={{ cursor: "pointer" }}
                  onClick={handleButtonHebrewNameClick}
                  ref={hebrewNameBtnRef}
                >
                  <svg
                    width={21}
                    height={20}
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.8451 1.66675C15.6317 1.66675 15.4184 1.74839 15.2559 1.91089L13.5892 3.57756L12.4108 4.75594L3 14.1667V17.5001H6.33333L18.5892 5.24422C18.915 4.91839 18.915 4.39084 18.5892 4.06584L16.4342 1.91089C16.2717 1.74839 16.0584 1.66675 15.8451 1.66675ZM15.8451 3.67847L16.8216 4.65503L15.7441 5.7325L14.7676 4.75594L15.8451 3.67847ZM13.5892 5.93433L14.5658 6.91089L5.64323 15.8334H4.66667V14.8569L13.5892 5.93433Z"
                      fill="#2A3847"
                    />
                  </svg>
                </div>
              </>
            )}
          </div>
          <div className="single-funner-template__icons-row">
            {templateHeader?.format === "IMAGE" && (
              <Tooltip title={localization.singleTemplate.media.image}>
                <ImageOutlined sx={{ fontSize: 18 }} />
              </Tooltip>
            )}
            {templateHeader?.format === "VIDEO" && (
              <Tooltip title={localization.singleTemplate.media.video}>
                <VideoCameraBackOutlined sx={{ fontSize: 18 }} />
              </Tooltip>
            )}
            {templateHeader?.format === "DOCUMENT" && (
              <Tooltip title={localization.singleTemplate.media.doc}>
                <StickyNote2Outlined sx={{ fontSize: 18 }} />
              </Tooltip>
            )}
            {/* {["IMAGE", "VIDEO", "DOCUMENT"].includes(
              templateHeader?.format,
            ) && (
              <div className="single-funner-template__icon funner-icon">
                <svg
                  width={18}
                  height={18}
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.56315 1.4668L3.34242 6.68753H6.45065V11.2646C6.45065 11.84 6.93155 12.3209 7.5069 12.3209H9.6194C10.1947 12.3209 10.6756 11.84 10.6756 11.2646V6.68753H13.7839L8.56315 1.4668ZM8.56315 3.45827L10.3841 5.2792H9.26732V10.9125H7.85898V5.2792H6.74222L8.56315 3.45827ZM1.52148 14.4334V15.8417H15.6048V14.4334H1.52148Z"
                    fill="black"
                  />
                </svg>
              </div>
            )} */}
            {isVars.haveVars ? (
              <Tooltip title={"פרמטרים דינמיים"}>
                <Box className="single-funner-template__icon funner-icon" sx={{
                  "& svg text": {
                    fontWeight: "600 !important"
                  }
                }}>
                  <svg
                    width={20}
                    height={18}
                    viewBox="0 0 20 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.6118 7.5C13.2681 7.5 12.9868 7.1625 12.9868 6.75V5.25C12.9868 4.0125 12.1431 3 11.1118 3H9.23682V4.5H11.1118C11.4556 4.5 11.7368 4.8375 11.7368 5.25V6.75C11.7368 7.725 12.2618 8.565 12.9868 8.8725V9.1275C12.2618 9.435 11.7368 10.2675 11.7368 11.25V12.75C11.7368 13.1625 11.4556 13.5 11.1118 13.5H9.23682V15H11.1118C12.1431 15 12.9868 13.9875 12.9868 12.75V11.25C12.9868 10.8375 13.2681 10.5 13.6118 10.5H14.2368V7.5H13.6118Z"
                      fill="black"
                      transform="translate(5)"
                    />
                    <text
                      x={14}
                      y={13}
                      fontSize={14}
                      fill="black"
                    >
                      {isVars.count}
                    </text>
                    <path
                      d="M2.98682 5.25V6.75C2.98682 7.1625 2.70557 7.5 2.36182 7.5H1.73682V10.5H2.36182C2.70557 10.5 2.98682 10.8375 2.98682 11.25V12.75C2.98682 13.9875 3.83057 15 4.86182 15H6.73682V13.5H4.86182C4.51807 13.5 4.23682 13.1625 4.23682 12.75V11.25C4.23682 10.275 3.71182 9.435 2.98682 9.1275V8.8725C3.71182 8.565 4.23682 7.725 4.23682 6.75V5.25C4.23682 4.8375 4.51807 4.5 4.86182 4.5H6.73682V3H4.86182C3.83057 3 2.98682 4.0125 2.98682 5.25Z"
                      fill="black"
                      transform="translate(-1)"
                    />
                  </svg>
                </Box>
              </Tooltip>
            ) : (
              <div className="single-funner-template__icon funner-icon">
                <svg
                  width={17}
                  height={18}
                  viewBox="0 0 17 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.33133 1.75928C3.56118 1.75928 2.92371 2.39675 2.92371 3.1669V14.4279C2.92371 15.198 3.56118 15.8355 4.33133 15.8355H12.7771C13.5472 15.8355 14.1847 15.198 14.1847 14.4279V5.98215L9.96183 1.75928H4.33133ZM4.33133 3.1669H9.25802V6.68596H12.7771V14.4279H4.33133V3.1669ZM5.73896 8.7974V10.205H11.3695V8.7974H5.73896ZM5.73896 11.6126V13.0203H11.3695V11.6126H5.73896Z"
                    fill="#2A3847"
                  />
                </svg>
              </div>
            )}
            <div className="single-funner-template__icon funner-icon">
              <svg
                width={18}
                height={18}
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.0411 1.96167C5.93979 1.96167 3.4106 4.49086 3.4106 7.59217V8.29598V8.93106C3.00682 9.31609 2.70679 9.81139 2.70679 10.4074C2.70679 11.2815 3.31034 11.9297 4.07455 12.2467C4.99023 14.4354 6.82426 16.0379 9.0411 16.0379H11.1525V14.6303H9.0411C7.45157 14.6303 6.00834 13.4335 5.28285 11.5429L5.13302 11.1552L4.72062 11.0975C4.374 11.0498 4.11441 10.77 4.11441 10.4074C4.11441 10.1365 4.25124 9.92388 4.46494 9.8012L4.81822 9.59913V8.99979V8.29598C4.81822 7.89831 5.12436 7.59217 5.52204 7.59217H12.5602C12.9578 7.59217 13.264 7.89831 13.264 8.29598V8.99979V11.815H10.3869C10.3142 11.6094 10.1796 11.4314 10.0016 11.3054C9.82369 11.1794 9.61107 11.1115 9.393 11.1112C9.11301 11.1112 8.84448 11.2225 8.6465 11.4204C8.44851 11.6184 8.33729 11.887 8.33729 12.1669C8.33764 12.4336 8.4389 12.6903 8.62071 12.8853C8.80252 13.0804 9.05142 13.1994 9.3174 13.2185C9.34251 13.2213 9.36775 13.2226 9.393 13.2227H13.9678H14.6716C15.4458 13.2227 16.0792 12.5892 16.0792 11.815V10.4074C16.0792 9.63322 15.4458 8.99979 14.6716 8.99979V8.29598V7.59217C14.6716 4.49086 12.1424 1.96167 9.0411 1.96167ZM9.0411 3.36929C10.9101 3.36929 12.4832 4.57097 13.0427 6.2464C12.8869 6.2092 12.7266 6.18454 12.5602 6.18454H5.52204C5.35558 6.18454 5.19533 6.2092 5.03954 6.2464C5.59902 4.57097 7.17206 3.36929 9.0411 3.36929Z"
                  fill="#2A3847"
                />
              </svg>
            </div>
            <div className="single-funner-template__icon funner-icon _lang">
              {languageIcons[template.language]}
            </div>
            <div
              className="single-funner-template__icon funner-icon"
              style={{
                width: 13,
                height: 8,
                marginRight: 15,
                transform: isThisActive ? "rotate(180deg)" : "rotate(0)",
              }}
              onClick={isThisActive ? resetTemplate : null}
            >
              <svg
                width={13}
                height={8}
                viewBox="0 0 13 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.2117 0.0927734L6.6217 4.67277L2.0317 0.0927734L0.621704 1.50277L6.6217 7.50277L12.6217 1.50277L11.2117 0.0927734Z"
                  fill="#2A3847"
                />
              </svg>
            </div>
          </div>
        </div>
        <div className="single-funner-template__text-preview">
          {template.components
            .find((item) => item.type === "BODY")
            .text.substring(0, 45)
            .replace(/^[a-z\u05D0-\u05EA]+$/i, "")}
        </div>

        {isThisActive && (
          <div className="single-funner-template__body">
            <div
              className="manage-templates-tab__preview-content preview-content-manage-templates"
              style={{ marginTop: 10 }}
            >
              {templateHeader ? (
                <div className="preview-content-manage-templates__header">
                  {templateHeader.format === "TEXT" ? (
                    <p>
                      {
                        template.components.find(
                          (item) => item.type === "HEADER",
                        ).text
                      }
                    </p>
                  ) : (
                    <div
                      className={`preview-content-manage-templates__header-media ${
                        templateFileLink ? "_media-uploaded" : ""
                      }`}
                      data-type={templateHeader.format}
                    >
                      {templateFileLink ? (
                        <>
                          {templateHeader.format === "VIDEO" ? (
                            <video
                              src={templateFileLink}
                              style={{ maxWidth: "100%" }}
                            ></video>
                          ) : (
                            <img
                              src={
                                templateHeader.format === "DOCUMENT"
                                  ? templatePreviewsByFileType[
                                      getFileFormatFromURL(templateFileLink)
                                    ]
                                  : templateFileLink
                              }
                              alt="header media"
                            />
                          )}
                        </>
                      ) : (
                        <img
                          src={
                            templateHeader.format === "IMAGE"
                              ? changeImage
                              : templateHeader.format === "VIDEO"
                              ? videoPlus
                              : templateHeader.format === "DOCUMENT"
                              ? filePresent
                              : templateHeader.format === "LOCATION"
                              ? locationIMG
                              : ""
                          }
                          alt="header media"
                        />
                      )}

                      {["IMAGE", "DOCUMENT", "VIDEO"].includes(
                        templateHeader.format,
                      ) && (
                        <label
                          className="template-chat-sidebar__select"
                          style={noUpload ? { pointerEvents: "none" } : null}
                        >
                          <input
                            type="file"
                            accept={
                              templateHeader.format === "IMAGE"
                                ? "image/*"
                                : templateHeader.format === "DOCUMENT"
                                ? ".docx,.doc, .sls,.xlsx,.pdf,.zip"
                                : templateHeader.format === "VIDEO"
                                ? "video/*"
                                : null
                            }
                            onChange={handleImgUploadTemplate}
                          />
                        </label>
                      )}
                    </div>
                  )}
                </div>
              ) : (
                ""
              )}
              <div className="preview-content-manage-templates__text">
                {template?.components?.find((item) => item.type === "BODY")
                  ?.text
                  ? template.components.find((item) => item.type === "BODY")
                      .text
                  : "Template text"}
              </div>
              {template?.components?.some((item) => item.type === "FOOTER") && (
                <div className="preview-content-manage-templates__footer">
                  {
                    template.components.find((item) => item.type === "FOOTER")
                      .text
                  }
                </div>
              )}
              <div className="preview-content-manage-templates__bottom">
                <div className="preview-content-manage-templates__icon">
                  <svg
                    width={16}
                    height={16}
                    view-box="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.0001 4.66656L11.0601 3.72656L6.83344 7.95323L7.77344 8.89323L12.0001 4.66656ZM14.8268 3.72656L7.77344 10.7799L4.98677 7.9999L4.04677 8.9399L7.77344 12.6666L15.7734 4.66656L14.8268 3.72656ZM0.273438 8.9399L4.0001 12.6666L4.9401 11.7266L1.2201 7.9999L0.273438 8.9399Z"
                      fill="black"
                      fillOpacity="0.54"
                    ></path>
                  </svg>
                </div>
                <div className="preview-content-manage-templates__date">
                  12.12.2022 10:25
                </div>
              </div>
              {template?.components?.some(
                (item) => item.type === "BUTTONS",
              ) && (
                <div className="preview-content-manage-templates__buttons-container">
                  {template?.components
                    ?.find((item) => item.type === "BUTTONS")
                    ?.buttons.map((button, index) => (
                      <div
                        className={`preview-content-manage-templates__button preview-content-manage-templates__button--${button.type.toLowerCase()}`}
                        key={index}
                      >
                        <span className="_button-text">{button.text}</span>
                        <span className="_button-icon">
                          {button.type === "QUICK_REPLY" ? (
                            <svg
                              fill="#1976d2"
                              width="64px"
                              height="64px"
                              viewBox="0 0 32 32"
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                              <g
                                id="SVGRepo_tracerCarrier"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <g id="SVGRepo_iconCarrier">
                                {"{"}" "{"}"}
                                <title>reply</title>
                                {"{"}" "{"}"}
                                <path d="M3.488 15.136q0 0.96 0.8 1.472l10.72 7.136q0.416 0.288 0.896 0.32t0.928-0.224 0.704-0.672 0.256-0.896v-3.584q3.456 0 6.208 1.984t3.872 5.152q0.64-1.792 0.64-3.552 0-2.912-1.44-5.376t-3.904-3.904-5.376-1.44v-3.584q0-0.48-0.256-0.896t-0.704-0.672-0.928-0.224-0.896 0.32l-10.72 7.136q-0.8 0.512-0.8 1.504z" />
                              </g>
                            </svg>
                          ) : button.type === "URL" ? (
                            <svg
                              width="64px"
                              height="64px"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              stroke="#000000"
                            >
                              <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                              <g
                                id="SVGRepo_tracerCarrier"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <g id="SVGRepo_iconCarrier">
                                <path
                                  d="M11 4H4V18C4 19.1046 4.89543 20 6 20H18C19.1046 20 20 19.1046 20 18V13"
                                  stroke="#1976d2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                />
                                <path
                                  d="M9 15L20 4"
                                  stroke="#1976d2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                />
                                <path
                                  d="M15 4H20V9"
                                  stroke="#1976d2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                />
                              </g>
                            </svg>
                          ) : button.type === "PHONE_NUMBER" ? (
                            <svg
                              width="64px"
                              height="64px"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                              <g
                                id="SVGRepo_tracerCarrier"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <g id="SVGRepo_iconCarrier">
                                {"{"}" "{"}"}
                                <path
                                  d="M10.0376 5.31617L10.6866 6.4791C11.2723 7.52858 11.0372 8.90532 10.1147 9.8278C10.1147 9.8278 10.1147 9.8278 10.1147 9.8278C10.1146 9.82792 8.99588 10.9468 11.0245 12.9755C13.0525 15.0035 14.1714 13.8861 14.1722 13.8853C14.1722 13.8853 14.1722 13.8853 14.1722 13.8853C15.0947 12.9628 16.4714 12.7277 17.5209 13.3134L18.6838 13.9624C20.2686 14.8468 20.4557 17.0692 19.0628 18.4622C18.2258 19.2992 17.2004 19.9505 16.0669 19.9934C14.1588 20.0658 10.9183 19.5829 7.6677 16.3323C4.41713 13.0817 3.93421 9.84122 4.00655 7.93309C4.04952 6.7996 4.7008 5.77423 5.53781 4.93723C6.93076 3.54428 9.15317 3.73144 10.0376 5.31617Z"
                                  fill="#1976d2"
                                />
                                {"{"}" "{"}"}
                              </g>
                            </svg>
                          ) : button.type === "COPY_CODE" ? (
                            <svg
                              width="64px"
                              height="64px"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                              <g
                                id="SVGRepo_tracerCarrier"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <g id="SVGRepo_iconCarrier">
                                {"{"}" "{"}"}
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M19.5 16.5L19.5 4.5L18.75 3.75H9L8.25 4.5L8.25 7.5L5.25 7.5L4.5 8.25V20.25L5.25 21H15L15.75 20.25V17.25H18.75L19.5 16.5ZM15.75 15.75L15.75 8.25L15 7.5L9.75 7.5V5.25L18 5.25V15.75H15.75ZM6 9L14.25 9L14.25 19.5L6 19.5L6 9Z"
                                  fill="#1976d2"
                                />
                                {"{"}" "{"}"}
                              </g>
                            </svg>
                          ) : (
                            ""
                          )}
                        </span>
                        {button.type === "URL" &&
                          button.url?.match(/{{(\d+)}}/g) && (
                            <div className="_button-link">
                              (url: ${button.url})
                            </div>
                          )}
                      </div>
                    ))}
                </div>
              )}
            </div>
            <div className="chat-sidebar__template template-chat-sidebar">
              {varsData &&
                varsData?.length > 0 &&
                varsData?.some(
                  (component) =>
                    (component?.type === "HEADER" &&
                      component.format === "LOCATION") ||
                    component?.buttons
                      ?.find((btn) => btn.type === "URL")
                      ?.url?.match(/{{(\d+)}}/g) ||
                    component?.text?.match(/{{(\d+)}}/g),
                ) && (
                  <>
                    {varsData.some(
                      (comp) =>
                        (comp.type !== "HEADER" &&
                          comp.text?.match(varsRegular)) ||
                        (comp.type === "HEADER" &&
                          !["IMAGE", "DOCUMENT", "VIDEO"].includes(
                            comp.format,
                          )),
                    ) && (
                      <p className="template-chat-sidebar__title">
                        {localization.componentsTitle}
                      </p>
                    )}

                    {varsData.map((component, compIndex) => {
                      const varsList =
                        component.type === "BUTTONS"
                          ? component?.buttons
                              ?.find((btn) => btn.type === "URL")
                              ?.url?.match(varsRegular)
                          : component?.text?.match(varsRegular);
                      return (
                        <Fragment key={compIndex}>
                          {!(
                            component.type === "HEADER" &&
                            ["IMAGE", "DOCUMENT", "VIDEO"].includes(
                              component.format,
                            )
                          ) &&
                            ((((component.type === "HEADER" &&
                              component.format === "TEXT") ||
                              (component.type !== "HEADER" &&
                                component.type !== "BUTTONS")) &&
                              component?.text?.match(/{{(\d+)}}/g)) ||
                              (component.type === "HEADER" &&
                                component.format === "LOCATION") ||
                              (component.type === "BUTTONS" &&
                                component?.buttons
                                  ?.find((btn) => btn.type === "URL")
                                  ?.url?.match(/{{(\d+)}}/g))) && (
                              <>
                                <p className="template-chat-sidebar__template-msg">
                                  {localization[component.type.toLowerCase()]}
                                </p>
                                <ul className="template-chat-sidebar__list">
                                  {component.type === "HEADER" &&
                                  component.format === "LOCATION" ? (
                                    <>
                                      {[
                                        "Latitude",
                                        "Longitude",
                                        "Name",
                                        "Address",
                                      ].map((param, index) => (
                                        <label
                                          className="form-get-token-popup__input-wrapper"
                                          style={{ marginBottom: 15 }}
                                          key={index}
                                        >
                                          <span
                                            className="input-title"
                                            style={{
                                              color: "#2A3847",
                                              paddingTop: 1,
                                              paddingBottom: 1,
                                            }}
                                          >
                                            {"{"}
                                            {param}
                                            {"}"}
                                          </span>
                                          <input
                                            type="text"
                                            id={`param-input-${compIndex}-${index}`}
                                            data-component={component.type}
                                            data-param={index}
                                            data-header-location={param}
                                            className="form-get-token-popup__input"
                                            onKeyUp={() =>
                                              templatesWithSend
                                                ? createParamsObject()
                                                : noSendTemplateAction()
                                            }
                                          />
                                        </label>
                                      ))}
                                    </>
                                  ) : (
                                    <>
                                      {varsList?.map((param, index) => (
                                        <label
                                          className="form-get-token-popup__input-wrapper"
                                          style={{ marginBottom: 15 }}
                                          key={index}
                                        >
                                          <span
                                            className="funner-icon"
                                            style={{
                                              position: "absolute",
                                              top: "50%",
                                              left: 12,
                                              width: 24,
                                              height: 24,
                                              transform: "translateY(-50%)",
                                              zIndex: 5,
                                              cursor: "pointer",
                                            }}
                                            onClick={() =>
                                              toggleImplementedVars(
                                                `${compIndex}-${index}`,
                                              )
                                            }
                                          >
                                            <svg
                                              width={24}
                                              height={24}
                                              viewBox="0 0 24 24"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M22.5 16H20.3L22 12H17V18H19V23L22.5 16Z"
                                                fill="black"
                                                fillOpacity="0.54"
                                              />
                                              <path
                                                d="M4 17.17V4H20V10H22V4C22 2.9 21.1 2 20 2H4C2.9 2 2.01 2.9 2.01 4L2 22L6 18H15V16H5.17L4 17.17Z"
                                                fill="black"
                                                fillOpacity="0.54"
                                              />
                                            </svg>
                                          </span>
                                          {showImplementedVars.show &&
                                            showImplementedVars.id ===
                                              `${compIndex}-${index}` &&
                                            implementedVars?.length && (
                                              <div className="implemented-variables-list">
                                                {implementedVars.map(
                                                  (variable, varIndex) => (
                                                    <Tooltip
                                                      title={variable.label}
                                                      placement="right"
                                                      key={varIndex}
                                                    >
                                                      <Box
                                                        className="create-new-task-messaging__variable"
                                                        sx={{
                                                          border:
                                                            "0 !important",
                                                        }}
                                                        onClick={() =>
                                                          placeVarToInput(
                                                            `${compIndex}-${index}`,
                                                            chatUser
                                                              ? paramNameByVariable[
                                                                  variable
                                                                    .varName
                                                                ](chatUser)
                                                              : variable.varName,
                                                          )
                                                        }
                                                      >
                                                        {chatUser
                                                          ? paramNameByVariable[
                                                              variable.varName
                                                            ](chatUser)
                                                          : variable.label}
                                                        {/* {variable.varName} */}
                                                        {/* <Tippy
                                                        content={
                                                          <p>
                                                            {
                                                              variable.tooltipContent
                                                            }
                                                          </p>
                                                        }
                                                        {...tippyPrefs}
                                                      >
                                                        <div className="create-new-task-messaging__variable-tooltip">
                                                          <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            x="0px"
                                                            y="0px"
                                                            width={100}
                                                            height={100}
                                                            viewBox="0 0 50 50"
                                                          >
                                                            <path d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 25 11 A 3 3 0 0 0 22 14 A 3 3 0 0 0 25 17 A 3 3 0 0 0 28 14 A 3 3 0 0 0 25 11 z M 21 21 L 21 23 L 22 23 L 23 23 L 23 36 L 22 36 L 21 36 L 21 38 L 22 38 L 23 38 L 27 38 L 28 38 L 29 38 L 29 36 L 28 36 L 27 36 L 27 21 L 26 21 L 22 21 L 21 21 z" />
                                                          </svg>
                                                        </div>
                                                      </Tippy> */}
                                                      </Box>
                                                    </Tooltip>
                                                  ),
                                                )}
                                              </div>
                                            )}
                                          <span
                                            className="input-title"
                                            style={{
                                              color: "#2A3847",
                                              paddingTop: 1,
                                              paddingBottom: 1,
                                            }}
                                          >
                                            {"{"}
                                            {index + 1}
                                            {"}"}
                                          </span>
                                          <input
                                            type="text"
                                            id={`param-input-${compIndex}-${index}`}
                                            data-component={component.type}
                                            data-param={index}
                                            className="form-get-token-popup__input"
                                            onKeyUp={() =>
                                              templatesWithSend
                                                ? createParamsObject()
                                                : noSendTemplateAction()
                                            }
                                          />
                                        </label>
                                      ))}
                                    </>
                                  )}
                                </ul>
                              </>
                            )}
                        </Fragment>
                      );
                    })}
                  </>
                )}
              <div className="templates-form-error">יש למלא את כל השדות</div>
              {templatesWithSend && (
                <Button
                  variant="contained"
                  onClick={handleSendTemplate}
                  endIcon={
                    <Send
                      sx={{
                        color: "common.white",
                      }}
                    />
                  }
                  fullWidth
                  sx={{
                    p: "6px 16px !important",
                    // width: 200,
                    display: "flex",
                    margin: "0 0 0 auto !important",
                    "& .MuiButton-icon": {
                      marginRight: "-4px !important",
                      marginLeft: "8px !important",
                      transform: "rotate(-180deg)",
                    },
                  }}
                >
                  {localization.singleTemplate.send}
                </Button>
                // <button
                //   className="send-btn chat-input-actions__green-btn"
                //   onClick={handleSendTemplate}
                // >
                //   <span className="icon plane">
                //     <img src={paperPlaneSendIcon} alt="" />
                //   </span>
                // </button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default SingleTemplate;
