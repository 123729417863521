import React, { useEffect, useRef, useState } from "react";
import PrioritySendDocsChat from "./PrioritySendDocsChat";
import { findPriorityPageType } from "./PriorityData";
import PriorityDocuments from "./PriorityDocuments";
import { getElementOffset } from "../../libs/helpers";
import { hintLogo } from "../../libs/images";
import ChatUsersPrioritySync from "./ChatUsersPrioritySync";
import localizations from "../../localizations.json";
import { UseFetchGet } from "../../hooks/fetchFunctions";
import { createPortal } from "react-dom";
import { applicationType } from "../../applicationType";
import { Person } from "@mui/icons-material";
import { CircularProgress, Fab, SvgIcon } from "@mui/material";
import { phoneRegex } from "../../libs/regexes";

let watcherInterval;
let pageUrl = window.location.href;
let isMainView = false;
let isUserView = false;
let prevIsTableView = false;
let prevView = null;

let shiftPressedCount = 0;
let lastKeyPressTime = 0;
const SHIFT_KEY_CODE = 16;
const TIME_INTERVAL = 500;
let isKeyPressed = false;

function PriorityPlatform({
  userData,
  userDataRef,
  businessInfo,
  businessInfoRef,
  selectedFunnerChatUser,
  isPrioritySendDocsWindow,
  handlePrioritySendDocsWindow,
  handleSetNotif,
  templatesList,
  isPriorityDocumentWindow,
  handlePriorityDocumentsWindow,
  funnerUsers,
  chatUsersGlobal,
  chatUsersGlobalRef,
  handleSelectedFunnerChatUser,
  isActiveAppOnPageRef,
  setOpenUnreadMessagesPopup,
  fetchWhatsappTemplates,
  isUsersPrioritySyncWindow,
  handleUsersPrioritySettingsWindow,
  selectedUsersPrioritySync,
  setSelectedUsersPrioritySync,
  sendUploadPriority,
  handleSendPriorityUploadState,
  resetSendUploadPriority,
  appButtonsRef,
  disableChatGoBack,
  setDisableChatGoBack,
  openUnreadMessagesPopup,
}) {
  const [localization, setLocalization] = useState(
    localizations["he"].priority,
  );

  const [isPriorityPage, setIsPriorityPage] = useState(false);
  const [priorityDocumentId, setPriorityDocumentId] = useState(null);
  const [priorityDocTypes, setPriorityDocTypes] = useState({});
  const [showPriorityBtn, setShowPriorityBtn] = useState(false);
  const [priorityBtnLoading, setPriorityBtnLoading] = useState(false);

  const priorityDocumentIdRef = useRef(null);
  const lastFocusedElemRef = useRef(null);
  const docBtnRef = useRef(null);
  const lastDoubleShiftFocusedRef = useRef(null);
  const selectedFunnerUserRef = useRef(null);
  const openUnreadMessagesPopupRef = useRef(null);
  const showPriorityBtnRef = useRef(null);
  const showPriorityBtnRippleRef = useRef(null);

  function linkWatcherFlow() {
    handlePlatforms(pageUrl);

    if (watcherInterval) {
      clearInterval(watcherInterval);
    }

    watcherInterval = setInterval(async () => {
      const urlLocal = pageUrl;
      const url = window.location.href;
      if (urlLocal !== url) {
        console.log("PlatformsDebug url changed");
        handlePlatforms(url);
        pageUrl = window.location.href.toString();
      }
    }, 10);
  }
  function handlePlatforms(url) {
    try {
      const powerLinkPage =
        url.includes("app.powerlink.co.il") ||
        url.includes("app.fireberry.com");

      const priorityDomain =
        businessInfoRef.current?.CompanyPlatforms &&
        JSON.parse(businessInfoRef.current.CompanyPlatforms)?.priority?.domain;

      const priorityPage = priorityDomain
        ? url.includes(priorityDomain)
        : false;

      setIsPriorityPage(priorityPage);

      console.log("PlatformsDebug powerLinkPage: " + powerLinkPage);
      console.log("PlatformsDebug priorityPage: " + priorityPage);

      if (priorityPage) {
        let previousActiveNumber = null;
        let isChatClosedOnTable = false;
        let isButtonInserted = false;
        isMainView = false;
        isUserView = false;
        prevIsTableView = false;
        let documentId = null;
        let lastActiveRow = null;

        let prevPageType = null;
        let prevDocumentId = null;

        doubleShiftInputAction();

        const priorityInputInterval = setInterval(() => {
          const inputs = document.querySelectorAll("input");
          if (inputs.length) {
            const inputsArr = Array.from(inputs);

            //! Documents
            const headerTitleElement = document.querySelector(
              ".header-panel .upper-section .bread-crumbs [anchor-id='form_header_title']",
            )?.children[0];
            const headerTitle =
              headerTitleElement && headerTitleElement.innerText;
            const breadCrumbsParent = headerTitleElement?.closest("#reactHeader");
            const isBreadcrumbsVisible = breadCrumbsParent && getComputedStyle(breadCrumbsParent)?.getPropertyValue("display") === "block";

            const invoicePageType =
              headerTitle && isBreadcrumbsVisible && findPriorityPageType(headerTitle);

            if (invoicePageType && invoicePageType.type !== prevPageType) {
              console.log(
                `Priority Page Type: ${invoicePageType.type}, Input Title: ${invoicePageType.inputTitle}`,
              );
              prevPageType = invoicePageType.type;
            } else if (
              !prevPageType ||
              (!invoicePageType && prevPageType !== "NoInvoice")
            ) {
              console.log("Priority Page Type: not Invoice Page");
              prevPageType = "NoInvoice";
            }

            if (invoicePageType) {
              const invoiceInput = document
                .querySelector(
                  `.formTableView_Painted:not([aria-hidden='true']) div[title="${invoicePageType.inputTitle}"]`,
                )
                ?.closest("tr")
                .querySelector("td:nth-child(3) input");
              const newDocumentId = invoiceInput ? invoiceInput.value : null;

              if (
                newDocumentId &&
                newDocumentId !== "" &&
                newDocumentId !== documentId
              ) {
                console.log(`Priority New Document ID: ${newDocumentId}`);
                documentId = newDocumentId;
                handlePriorityDocumentDialog(documentId);
              } else if (newDocumentId === "" && documentId) {
                console.log("Priority No Document ID");
                removePriorityDocumentDialog();
                documentId = null;
              }
            } else {
              removePriorityDocumentDialog();
              documentId = null;
            }

            //! Priority contacts
            const headerBreadcrumbs = document.querySelector(
              ".header-panel .upper-section .bread-crumbs",
            );
            // const breadCrumbsParent = headerBreadcrumbs?.closest("#reactHeader");
            // const isBreadcrumbsVisible = breadCrumbsParent && getComputedStyle(breadCrumbsParent)?.getPropertyValue("display") === "block";
            const isMainViewInBreadCrumbs =
              headerBreadcrumbs &&
              headerBreadcrumbs?.children?.length >= 1 &&
              headerBreadcrumbs.children[0]?.children[0]?.children[0]
                ?.innerText === "לקוחות";
            isMainView = isMainViewInBreadCrumbs && isBreadcrumbsVisible;

            const isUserViewInBreadCrumbs =
              headerBreadcrumbs &&
              headerBreadcrumbs?.children?.length > 1 &&
              headerBreadcrumbs.children[1]?.children[0]?.children[0]
                ?.innerText === "אנשי קשר ללקוח";
            isUserView = isUserViewInBreadCrumbs && isBreadcrumbsVisible;

            let tableView;
            const isTableView = inputsArr.some((input) => {
              const inputTableParent = input.closest(".form-table-view");
              tableView = inputTableParent;
              return (
                inputTableParent &&
                !inputTableParent.hasAttribute("aria-hidden")
              );
            });

            // console.log("Priority userViewPhoneInput: ", userViewPhoneInput)
            // console.log("Priority userViewInputParent: ", userViewInputParent)
            // console.log("Priority isUserViewInBreadCrumbs: ", isUserViewInBreadCrumbs);
            // console.log(`!userViewInputParent.getAttribute("aria-hidden"): ` + !userViewInputParent?.getAttribute("aria-hidden"))

            // console.log("Priority else: ", !(isUserView || (isMainView && !isUserViewInBreadCrumbs)))

            if (!isMainView) lastActiveRow = null;

            if (isUserView) {
              isChatClosedOnTable = false;
            } else if (isMainView && !isUserViewInBreadCrumbs) {
              if (prevIsTableView) {
                const isChatOpen =
                  openUnreadMessagesPopupRef.current &&
                  selectedFunnerUserRef.current;

                const targetRow = document.querySelector(
                  ".net-form-scroll .form-table-view:not([aria-hidden]) [__gwt_row].priCurrentRowStyle",
                );

                if (isChatOpen && targetRow !== lastActiveRow) {
                  onDoubleShift(true);
                }

                lastActiveRow = targetRow;
              }
              isChatClosedOnTable = false;
            } else if (isTableView) {
              //* Do this code once when we go to table view
              if (isTableView !== prevIsTableView) {
                const testColHeader = (prevIsTableView =
                  tableView.querySelector(`[title]`));
                if (testColHeader) {
                  console.log("Table view");
                  console.log(testColHeader.getAttribute("title"));
                }
              }
            } else {
              if (previousActiveNumber) {
                handleSelectedFunnerChatUser(null);
                previousActiveNumber = null;
              }
            }

            if (invoicePageType) {
              if (prevView !== "invoicePage") console.log("Priority Page Type: Invoice");
              let isBtn = false;
              if (isTableView) {
                const targetRow = document.querySelector(
                  ".net-form-scroll .form-table-view:not([aria-hidden]) [__gwt_row].priCurrentRowStyle",
                );
                const parentTable = targetRow.closest(".form-table-view");

                if (targetRow && parentTable) {
                  const phoneColHeadCell = parentTable.querySelector(
                    `[title="${invoicePageType.inputTitle}"]`,
                  );
                  const colNumber = phoneColHeadCell?.parentElement
                    ? Array.from(
                        phoneColHeadCell.parentElement.children,
                      ).indexOf(phoneColHeadCell)
                    : null;

                  if (colNumber) {
                    const targetDocInput = targetRow.querySelector(
                      `td:nth-child(${colNumber + 1}) input`,
                    );
                    isBtn = targetDocInput?.value?.length > 0 || false;
                  }
                }
              } else isBtn = true;
              // if (isBtn !== Boolean(showPriorityBtnRef.current))
              if (isBtn !== (showPriorityBtnRef.current === "doc"))
                setShowPriorityBtn(isBtn ? "doc" : null);
            } else if (isUserView) {
              if (prevView !== "userView") console.log("Priority Page Type: User View");
              const parentTable =
                document
                  .querySelector(
                    '.net-form-scroll [id^="FormTableView_Table_Id-"]:not(.form-table-view):not([aria-hidden]) .formGridWidget',
                  )
                  ?.closest(`[id^="FormTableView_Table_Id-"]`) || null;

              const isTableView = parentTable
                ? !parentTable.hasAttribute("aria-hidden")
                : false;

              const userViewInputLabel = document.querySelector(
                `.formTableView_Painted:not([aria-hidden="true"]) table [title="טלפון ראשי"]`,
              );

              let isActiveInTableView = false;
              if (isTableView) {
                const targetRow = document.querySelector(
                  ".net-form-scroll .form-table-view:not([aria-hidden]) [__gwt_row].priCurrentRowStyle",
                );
                const parentTable = targetRow.closest(".form-table-view");

                if (targetRow && parentTable) {
                  const phoneColHeadCell =
                    parentTable.querySelector(`[title*="טלפון"]`);
                  const phoneColNumber = phoneColHeadCell?.parentElement
                    ? Array.from(
                        phoneColHeadCell.parentElement.children,
                      ).indexOf(phoneColHeadCell)
                    : null;
                  if (phoneColNumber) {
                    const targetPhoneInput = targetRow.querySelector(
                      `td:nth-child(${phoneColNumber + 1}) input`,
                    );
                    isActiveInTableView = targetPhoneInput?.value?.length > 0;
                  }
                }
              }

              const isBtn = Boolean(userViewInputLabel);
              if (
                // isBtn !== Boolean(showPriorityBtnRef.current) ||
                // isActiveInTableView !== Boolean(showPriorityBtnRef.current)
                isBtn !== (showPriorityBtnRef.current === "contact") ||
                isActiveInTableView !== (showPriorityBtnRef.current === "contact")
              )
                setShowPriorityBtn(
                  isBtn || isActiveInTableView ? "contact" : null,
                );

              if (prevView !== "userView") {
                setDisableChatGoBack(true);
              }
            } else if (
              isMainView &&
              !isUserViewInBreadCrumbs
              // && prevView !== "mainView"
            ) {
              if (prevView !== "mainView") console.log("Priority Page Type: Main View");
              if (prevView !== "mainView") setDisableChatGoBack(true);
              let isBtn = false;
              if (isTableView) {
                const targetRow = document.querySelector(
                  ".net-form-scroll .form-table-view:not([aria-hidden]) [__gwt_row].priCurrentRowStyle",
                );
                const parentTable = targetRow.closest(".form-table-view");

                if (targetRow && parentTable) {
                  const phoneColHeadCell =
                    parentTable.querySelector(`[title*="טלפון"]`);
                  const phoneColNumber = phoneColHeadCell?.parentElement
                    ? Array.from(
                        phoneColHeadCell.parentElement.children,
                      ).indexOf(phoneColHeadCell)
                    : null;
                  if (phoneColNumber) {
                    const targetPhoneInput = targetRow.querySelector(
                      `td:nth-child(${phoneColNumber + 1}) input`,
                    );
                    isBtn = targetPhoneInput?.value?.length > 0 || false;
                  }
                }
              } else isBtn = true;
              if (isBtn !== Boolean(showPriorityBtnRef.current === "contact"))
                setShowPriorityBtn(isBtn ? "contact" : null);
            } else if (isTableView && prevView !== "tableView") {
              console.log("Priority Page Type: Table View")
              setDisableChatGoBack(false);

              setShowPriorityBtn(invoicePageType ? "doc" : "contact");
            } else if (
              !isUserView &&
              !invoicePageType &&
              !isMainView &&
              !isTableView &&
              prevView
            ) {
              console.log("No page")
              setDisableChatGoBack(false);
              setShowPriorityBtn(false);
            }

            prevView = invoicePageType
              ? "invoicePage"
              : isUserView
              ? "userView"
              : isMainView && !isUserViewInBreadCrumbs
              ? "mainView"
              : isTableView
              ? "tableView"
              : null;

            prevIsTableView = isTableView;
          } else {
            if (prevView) {
              setShowPriorityBtn(false);
              setDisableChatGoBack(false);
            }
          }
        }, 200);
      } else if (!powerLinkPage && !priorityPage) {
        if (isActiveAppOnPageRef.current) {
          const rootElement = document.body;
          const phoneNumbersElements = findIsraeliPhoneNumbers(rootElement);

          if (phoneNumbersElements.length) {
            phoneNumbersElements.forEach((elem) => {
              const element = elem.element;
              const elementCoords = getElementOffset(element);

              const funnerButton = document.createElement("button");
              funnerButton.classList.add(
                "funner-status__button",
                "funner-status__button--chat",
                "funner-status__button--page-inserted",
              );
              funnerButton.addEventListener("click", () => {
                goToChatWithPriorityUser(elem.phone);
              });
              funnerButton.style.left =
                elementCoords.left + element.offsetWidth + "px";
              funnerButton.style.top =
                elementCoords.top + element.offsetHeight / 2 + "px";
              const funnerButtonIcon = document.createElement("img");
              funnerButtonIcon.src = hintLogo;
              funnerButtonIcon.alt = "";

              funnerButton.appendChild(funnerButtonIcon);
              // elem.element.appendChild(funnerButton);
              document.body.appendChild(funnerButton);
            });
          }
        }
        if (showPriorityBtn) setShowPriorityBtn(false);
        if (disableChatGoBack) setDisableChatGoBack(false);
      } else {
        if (showPriorityBtn) setShowPriorityBtn(false);
        if (disableChatGoBack) setDisableChatGoBack(false);
      }
    } catch (error) {
      console.log(error);
    }
  }
  function doubleShiftInputAction() {
    function shiftKeyPressedTwice() {
      shiftPressedCount = 0;
      lastKeyPressTime = 0;
      isKeyPressed = false;

      document.removeEventListener("keyup", onKeyDown);
      // document.removeEventListener("keyup", onKeyUp);

      document.addEventListener("keyup", onKeyDown);
      // document.addEventListener("keyup", onKeyUp);
    }
    shiftKeyPressedTwice();
  }
  function onKeyDown(e) {
    if (
      e.keyCode === SHIFT_KEY_CODE ||
      e.code === SHIFT_KEY_CODE
      // && !isKeyPressed
    ) {
      isKeyPressed = true;
      const currentTime = new Date().getTime();

      if (currentTime - lastKeyPressTime < TIME_INTERVAL) {
        shiftPressedCount++;
      } else {
        shiftPressedCount = 1;
      }

      lastKeyPressTime = currentTime;

      if (shiftPressedCount >= 2) {
        console.log("double shift!");
        onDoubleShift();
        triggerRipple(showPriorityBtnRippleRef);

        shiftPressedCount = 0;
        return true;
      }
    }
    return false;
  }
  function onKeyUp(e) {
    if (e.keyCode === SHIFT_KEY_CODE || e.code === SHIFT_KEY_CODE)
      isKeyPressed = false;
  }
  function onDoubleShift(noClose = false) {
    const focusedEl = document.activeElement;
    if (!prevView) return;
    if (
      selectedFunnerUserRef.current &&
      openUnreadMessagesPopupRef.current &&
      !noClose
    )
      return setOpenUnreadMessagesPopup(false);
    // if (
    //   focusedEl &&
    //   focusedEl.tagName === "INPUT" &&
    //   focusedEl.type === "text"
    // ) {
    let resStr = "";
    lastDoubleShiftFocusedRef.current = focusedEl;

    const value = focusedEl.value;
    const headerTitleElement = document.querySelector(
      ".header-panel .upper-section .bread-crumbs [anchor-id='form_header_title']",
    )?.children[0];
    const headerTitle = headerTitleElement && headerTitleElement.innerText;
    const invoicePageType = headerTitle && findPriorityPageType(headerTitle);

    if (invoicePageType) {
      if (prevIsTableView) {
        //* Here goes logic for table view docs (open doc)
        console.log("invoice page table view double shift");
        // const targetRow = focusedEl.closest("tr");

        const targetRow = document.querySelector(
          ".net-form-scroll .form-table-view:not([aria-hidden]) [__gwt_row].priCurrentRowStyle",
        );
        const parentTable = targetRow.closest(".form-table-view");

        if (!targetRow || !parentTable) return;

        const phoneColHeadCell = parentTable.querySelector(
          `[title="${invoicePageType.inputTitle}"]`,
        );
        const colNumber = phoneColHeadCell?.parentElement
          ? Array.from(phoneColHeadCell.parentElement.children).indexOf(
              phoneColHeadCell,
            )
          : null;

        if (colNumber) {
          const targetDocInput = targetRow.querySelector(
            `td:nth-child(${colNumber + 1}) input`,
          );

          if (targetDocInput) {
            const docId = targetDocInput.value;
            if (docId) {
              priorityDocumentIdRef.current = docId;
              setPriorityDocumentId(docId);
              handlePriorityDocumentsWindow(true);
            }
          }
        } else alert(localization.notifications.documents.noDocCell);
      } else {
        //* Here goes logic for card (main) view docs (open doc)
        console.log("invoice page main view double shift");
        const invoiceInput = document
          .querySelector(
            `.net-form-scroll .formTableView_Painted:not([aria-hidden='true']) div[title="${invoicePageType.inputTitle}"]`,
          )
          ?.closest("tr")
          .querySelector("td:nth-child(3) input");
        const newDocumentId = invoiceInput ? invoiceInput.value : null;
        if (newDocumentId) {
          handlePriorityDocumentsWindow(true);
        }
      }
    } else if (headerTitle === "לקוחות") {
      if (prevIsTableView) {
        //* Here goes logic for table view customers (open chat)
        console.log("customers page table view double shift");
        // const targetRow = focusedEl.closest("tr");
        const targetRow = document.querySelector(
          ".net-form-scroll .form-table-view:not([aria-hidden]) [__gwt_row].priCurrentRowStyle",
        );
        const parentTable = targetRow.closest(".form-table-view");

        console.log("targetRow: ", targetRow);
        console.log("parentTable: ", parentTable);

        if (!targetRow || !parentTable) return;

        const phoneColHeadCell = parentTable.querySelector(`[title*="טלפון"]`);
        const fullNameColHeadCell =
          parentTable.querySelector(`[title="שם לקוח"]`);

        console.log("phoneColHeadCell: ", phoneColHeadCell);
        console.log("fullNameColHeadCell: ", fullNameColHeadCell);

        const phoneColNumber = phoneColHeadCell?.parentElement
          ? Array.from(phoneColHeadCell.parentElement.children).indexOf(
              phoneColHeadCell,
            )
          : null;
        const fullNameColNumber = fullNameColHeadCell?.parentElement
          ? Array.from(fullNameColHeadCell.parentElement.children).indexOf(
              fullNameColHeadCell,
            )
          : null;

        console.log("phoneColNumber: ", phoneColNumber);
        console.log("fullNameColNumber: ", fullNameColNumber);

        if (phoneColNumber) {
          const targetPhoneInput = targetRow.querySelector(
            `td:nth-child(${phoneColNumber + 1}) input`,
          );
          let fullName = null;
          if (fullNameColNumber) {
            const targetFullNameInput = targetRow.querySelector(
              `td:nth-child(${fullNameColNumber + 1}) input`,
            );

            fullName = targetFullNameInput?.value || "";
          }

          if (targetPhoneInput) {
            goToChatWithPriorityUser(targetPhoneInput.value, fullName);
            // setOpenUnreadMessagesPopup(true);
          }
        } else alert(localization.notifications.customers.noPhoneCell);
      } else {
        //* Here goes logic for card (main) view customers (open chat)
        console.log("customers page main view double shift");
        const phoneLabel = document.querySelector(
          `.net-form-scroll .formTableView_Painted:not([aria-hidden]) td [title="טלפון"]`,
        );

        const phoneInput = phoneLabel
          .closest("td")
          .nextElementSibling.querySelector("input");
        if (phoneInput) {
          const parentTable = phoneInput.closest(".formTableView_Painted");
          const userFullNameLabel =
            parentTable.querySelector(`[title="מס. לקוח"]`);
          const fullName =
            userFullNameLabel
              ?.closest("td")
              ?.nextElementSibling?.nextElementSibling?.querySelector("input")
              ?.value || "";

          goToChatWithPriorityUser(phoneInput.value, fullName);
          // setOpenUnreadMessagesPopup(true);
        }
      }
    } else if (prevView === "userView") {
      const parentTable =
        document
          .querySelector(
            '.net-form-scroll [id^="FormTableView_Table_Id-"]:not(.form-table-view):not([aria-hidden]) .formGridWidget',
          )
          ?.closest(`[id^="FormTableView_Table_Id-"]`) || null;

      const isTableView = parentTable
        ? !parentTable.hasAttribute("aria-hidden")
        : false;
      if (isTableView) {
        //* Here goes logic for user card table view customers (open chat)
        console.log("customers page user card table view double shift");
        // const targetRow = focusedEl.closest("tr");
        const targetRow = parentTable.querySelector(
          "[__gwt_row].priCurrentRowStyle",
        );

        console.log("targetRow: ", targetRow);
        console.log("parentTable: ", parentTable);

        if (!targetRow || !parentTable) return;

        const phoneColHeadCell = parentTable.querySelector(`[title*="טלפון"]`);
        const firstNameColHeadCell =
          parentTable.querySelector(`[title="שם פרטי"]`);
        const lastNameColHeadCell =
          parentTable.querySelector(`[title="שם משפחה"]`);

        console.log("phoneColHeadCell: ", phoneColHeadCell);
        console.log("firstNameColHeadCell: ", firstNameColHeadCell);
        console.log("lastNameColHeadCell: ", lastNameColHeadCell);

        const phoneColNumber = phoneColHeadCell?.parentElement
          ? Array.from(phoneColHeadCell.parentElement.children).indexOf(
              phoneColHeadCell,
            )
          : null;
        const firstNameColNumber = firstNameColHeadCell?.parentElement
          ? Array.from(firstNameColHeadCell.parentElement.children).indexOf(
              firstNameColHeadCell,
            )
          : null;
        const lastNameColNumber = lastNameColHeadCell?.parentElement
          ? Array.from(lastNameColHeadCell.parentElement.children).indexOf(
              lastNameColHeadCell,
            )
          : null;

        console.log("phoneColNumber: ", phoneColNumber);
        console.log("firstNameColNumber: ", firstNameColNumber);
        console.log("lastNameColNumber: ", lastNameColNumber);

        if (phoneColNumber) {
          const targetPhoneInput = targetRow.querySelector(
            `td:nth-child(${phoneColNumber + 1}) input`,
          );
          let fullName = null;
          if (firstNameColNumber && lastNameColNumber) {
            const targetFirstNameInput = targetRow.querySelector(
              `td:nth-child(${firstNameColNumber + 1}) input`,
            );
            const targetLastNameInput = targetRow.querySelector(
              `td:nth-child(${lastNameColNumber + 1}) input`,
            );

            const userFirstName = targetFirstNameInput?.value || null;
            const userLastName = targetLastNameInput?.value || null;

            fullName =
              !userFirstName && !userLastName
                ? null
                : `${userFirstName ? userFirstName : ""} ${
                    userLastName ? userLastName : ""
                  }`;
          }

          if (targetPhoneInput) {
            goToChatWithPriorityUser(targetPhoneInput.value, fullName);
            // setOpenUnreadMessagesPopup(true);
          }
        } else alert(localization.notifications.customers.noPhoneCell);
      } else {
        const userViewInputLabel = document.querySelector(
          `.net-form-scroll .formTableView_Painted:not([aria-hidden="true"]) table [title="טלפון ראשי"]`,
        );

        const cardViewParent =
          userViewInputLabel?.closest(".formTableView_Painted") || null;
        if (!userViewInputLabel || !cardViewParent) return;

        const userViewPhoneInput =
          userViewInputLabel
            ?.closest("td")
            ?.nextElementSibling?.querySelector("input") || null;
        if (userViewPhoneInput) {
          const userFirstNameLabel = document.querySelector(
            `.formTableView_Painted [title="שם פרטי"]`,
          );
          const userLastNameLabel = document.querySelector(
            `.formTableView_Painted [title="שם משפחה"]`,
          );

          const userFirstName =
            userFirstNameLabel
              ?.closest("td")
              ?.nextElementSibling.querySelector("input")?.value || null;
          const userLastName =
            userLastNameLabel
              ?.closest("td")
              ?.nextElementSibling.querySelector("input")?.value || null;

          const fullName =
            !userFirstName && !userLastName
              ? null
              : `${userFirstName ? userFirstName : ""} ${
                  userLastName ? userLastName : ""
                }`;

          goToChatWithPriorityUser(userViewPhoneInput.value, fullName);
        }
      }
    }

    // let inputLabel = "";
    // if (prevIsTableView) {
    //   const parentIndex = focusedEl.closest("tr")
    //     ? Array.from(focusedEl.closest("tr").children).indexOf(
    //         focusedEl.closest("td"),
    //       )
    //     : null;
    //   if (parentIndex !== null) {
    //     const headCell = focusedEl
    //       .closest(".formGridWidget")
    //       .querySelector(
    //         `div:first-child table thead tr th:nth-child(${parentIndex + 1})`,
    //       );
    //     const textElem = headCell.querySelector(
    //       ".header-text-wrapper .text-container",
    //     );
    //     inputLabel = textElem.innerText || "";
    //   }
    // } else {
    //   const parent = focusedEl.closest("td");
    //   let prevElem = parent?.previousElementSibling;

    //   while (prevElem) {
    //     if (prevElem.children[0]?.hasAttribute("title")) {
    //       break;
    //     }

    //     prevElem = prevElem.previousElementSibling;
    //   }
    //   inputLabel = prevElem?.children[0]?.getAttribute("title") || "";
    // }

    // if (value)
    //   resStr += `${localizations["he"].priority.doubleShift.valueText} ${value}`;
    // if (headerTitle)
    //   resStr += `\n${localizations["he"].priority.doubleShift.screenNameText} ${headerTitle}`;
    // if (inputLabel)
    //   resStr += `\n${localizations["he"].priority.doubleShift.labelText} ${inputLabel}`;
    // resStr += `\nView: ${prevIsTableView ? "Table View" : "Main View"}`;

    // if (value?.length) alert(resStr);
    // }
  }
  function findIsraeliPhoneNumbers(node) {
    const elementsWithPhoneNumbers = [];

    const walker = document.createTreeWalker(
      node,
      NodeFilter.SHOW_TEXT | NodeFilter.SHOW_ELEMENT,
      null,
      false,
    );

    while (walker.nextNode()) {
      const currentNode = walker.currentNode;

      if (currentNode.nodeType === Node.TEXT_NODE) {
        const text = currentNode.textContent;
        const matches = text.match(/^(972|0)5\d{8}$/g);

        if (matches) {
          elementsWithPhoneNumbers.push({
            phone: matches[0].startsWith("0")
              ? "972" + matches[0].slice(1)
              : matches[0],
            element: currentNode.parentElement,
          });
        }
      } else if (currentNode.nodeType === Node.ELEMENT_NODE) {
        // Обработка элементов (например, input)
        const input = currentNode;
        if (
          input.tagName.toLowerCase() === "input" ||
          input.tagName.toLowerCase() === "textarea"
        ) {
          const value = input.value;
          const matches = value.match(/^(972|0)5\d{8}$/g);

          if (matches) {
            // Если найден номер, добавляем элемент input в массив
            elementsWithPhoneNumbers.push({
              phone: matches[0].startsWith("0")
                ? "972" + matches[0].slice(1)
                : matches[0], // Найденный номер телефона
              element: input, // Элемент input
            });
          }
        }
      }
    }

    return elementsWithPhoneNumbers;
  }
  function handleDocBtnClick() {
    if (lastFocusedElemRef.current) {
      setTimeout(() => {
        lastFocusedElemRef.current.focus();
        onDoubleShift();
      }, 10);
    }
  }
  const triggerRipple = (ref) => {
    ref.current.start();
    setTimeout(() => {
      ref.current.stop();
    }, 300);
  };

  function handlePriorityDocumentDialog(docId) {
    priorityDocumentIdRef.current = docId;
    setPriorityDocumentId(docId);
  }
  function removePriorityDocumentDialog() {
    handlePriorityDocumentsWindow(false);
    priorityDocumentIdRef.current = null;
    setPriorityDocumentId(null);
  }

  async function getDocTypes() {
    try {
      const res = await UseFetchGet(
        "/api/priority/getDocTypes",
        undefined,
        null,
        undefined,
        "priority",
      );

      setPriorityDocTypes(res?.data?.length ? res.data : []);
    } catch (error) {
      console.log("getDocTypes error: ", error);
    }
  }
  async function goToChatWithPriorityUser(phone, userName = null) {
    if (!phone)
      return handleSetNotif(
        localization.notifications.customers.userHaveNoPhone,
        "error",
      );
    setPriorityBtnLoading(true);

    try {
      if (!phoneRegex.test(phone)) {
        const user = {
          UserDisplayPhoneNumber: phone,
          FullName: userName || "",
        };
        handleSelectedFunnerChatUser(user);
        setOpenUnreadMessagesPopup(true);
      } else {
        const params = {
          phoneNumberId: phone,
        };
        if (userName) params.userName = userName;
        const res = await UseFetchGet(
          "/api/services/getPriorityChatUserObject",
          params,
          null,
          undefined,
          "history",
        );

        if (res.status === 200) {
          const user = { ...res.data };
          if (userName) user.FullName = userName;
          handleSelectedFunnerChatUser(user);
          setOpenUnreadMessagesPopup(true);
        }
      }
    } catch (error) {
      console.log("getPriorityChatUserObject error: ", error);
    } finally {
      setPriorityBtnLoading(false);
    }
  }

  useEffect(() => {
    if (applicationType === "extension") {
      linkWatcherFlow();
    }

    getDocTypes();

    const onFocusIn = (event) => {
      if (event.target !== docBtnRef.current)
        lastFocusedElemRef.current = event.target;
    };
    document.addEventListener("focusin", onFocusIn);

    return () => document.removeEventListener("focusin", onFocusIn);
  }, []);

  useEffect(() => {
    if (isPriorityDocumentWindow === false && lastDoubleShiftFocusedRef.current)
      lastDoubleShiftFocusedRef.current.focus();
  }, [isPriorityDocumentWindow]);

  useEffect(() => {
    if (priorityBtnLoading) {
      document.documentElement.style.setProperty("cursor", "wait", "important");
    } else {
      document.documentElement.style.removeProperty("cursor");
    }
  }, [priorityBtnLoading]);

  useEffect(() => {
    if (!openUnreadMessagesPopup && lastDoubleShiftFocusedRef.current)
      lastDoubleShiftFocusedRef.current.focus();
  }, [openUnreadMessagesPopup]);

  useEffect(() => {
    selectedFunnerUserRef.current = selectedFunnerChatUser;
    openUnreadMessagesPopupRef.current = openUnreadMessagesPopup;
    showPriorityBtnRef.current = showPriorityBtn;
  }, [selectedFunnerChatUser, openUnreadMessagesPopup, showPriorityBtn]);

  return (
    <>
      {appButtonsRef.current &&
        showPriorityBtn &&
        createPortal(
          // <div
          //   className="funner-status__button"
          //   onClick={() => {
          //     // setPriorityDocSelectedId(priorityDocumentIdRef.current);
          //     // handlePriorityDocumentsWindow(true);
          //     handleDocBtnClick();
          //   }}
          //   ref={docBtnRef}
          // >
          <Fab
            size="small"
            color="primary"
            sx={{
              width: 30,
              minWidth: 30,
              height: 30,
              minHeight: 30,
            }}
            onClick={() => {
              handleDocBtnClick();
            }}
            ref={docBtnRef}
            touchRippleRef={showPriorityBtnRippleRef}
          >
            {priorityBtnLoading ? (
              <CircularProgress size={20} color="customWhite" />
            ) : showPriorityBtn === "doc" ? (
              <SvgIcon sx={{ fontSize: 15 }}>
                <svg
                  width="64px"
                  height="64px"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path
                      fill="#fff"
                      fillRule="evenodd"
                      d="M4 1a2 2 0 00-2 2v14a2 2 0 002 2h12a2 2 0 002-2V7.414A2 2 0 0017.414 6L13 1.586A2 2 0 0011.586 1H4zm0 2h7.586L16 7.414V17H4V3zm2 2a1 1 0 000 2h5a1 1 0 100-2H6zm-1 5a1 1 0 011-1h8a1 1 0 110 2H6a1 1 0 01-1-1zm1 3a1 1 0 100 2h8a1 1 0 100-2H6z"
                    />{" "}
                  </g>
                </svg>
              </SvgIcon>
            ) : showPriorityBtn === "contact" ? (
              <Person sx={{ color: "common.white", fontSize: 20 }} />
            ) : null}
          </Fab>,
          appButtonsRef.current,
        )}

      {isPriorityPage && (
        <PriorityDocuments
          businessInfo={businessInfo}
          isPriorityDocumentWindow={isPriorityDocumentWindow}
          handlePriorityDocumentsWindow={handlePriorityDocumentsWindow}
          priorityDocumentId={priorityDocumentId}
          priorityDocumentIdRef={priorityDocumentIdRef}
          globalID={userData.Udid}
          handleSetNotif={handleSetNotif}
          templatesList={templatesList}
          fetchWhatsappTemplates={fetchWhatsappTemplates}
          handleSendPriorityUploadState={handleSendPriorityUploadState}
          openFunnerChatWithUser={goToChatWithPriorityUser}
          priorityDocTypes={priorityDocTypes}
          funnerUsers={funnerUsers}
          chatUsersGlobal={chatUsersGlobal}
          chatUsersGlobalRef={chatUsersGlobalRef}
        />
      )}

      <PrioritySendDocsChat
        globalID={userDataRef.current.Udid}
        businessInfo={businessInfo}
        currentContact={selectedFunnerChatUser}
        isPrioritySendDocsWindow={isPrioritySendDocsWindow}
        handlePrioritySendDocsWindow={handlePrioritySendDocsWindow}
        handleSetNotif={handleSetNotif}
        templatesList={templatesList}
        fetchWhatsappTemplates={fetchWhatsappTemplates}
        handleSendPriorityUploadState={handleSendPriorityUploadState}
        priorityDocTypes={priorityDocTypes}
      />

      <ChatUsersPrioritySync
        isUsersPrioritySyncWindow={isUsersPrioritySyncWindow}
        handleUsersPrioritySettingsWindow={handleUsersPrioritySettingsWindow}
        selectedUsersPrioritySync={selectedUsersPrioritySync}
        setSelectedUsersPrioritySync={setSelectedUsersPrioritySync}
        handleSetNotif={handleSetNotif}
      />
    </>
  );
}

export default PriorityPlatform;
