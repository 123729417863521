import React, { memo, useRef } from "react";
import {
  docx,
  doubleV,
  doubleVgray,
  errorOrange,
  facebookRounded,
  forwardedLink,
  singleV,
  uploadPreview,
  xlsx,
  zip,
} from "../../../libs/images";
import { IsJsonString, getContrastYIQ } from "../../../libs/helpers";
import AudioMessage from "./MessageTypes/AudioMessage";
import VideoMessage from "./MessageTypes/VideoMessage";
import ContactsMessage from "./MessageTypes/ContactsMessage";
import ButtonsMessage from "./MessageTypes/ButtonsMessage";
import OrderMessage from "./MessageTypes/OrderMessage";
import TemplateMessage from "./MessageTypes/TemplateMessage";
import ReplyButtonMessage from "./MessageTypes/ReplyButtonMessage";
import NoteMessage from "./MessageTypes/NoteMessage";
import LocationMessage from "./MessageTypes/LocationMessage";
import LinkifiedText from "./linkifyText";
import deepEqual from "fast-deep-equal";
import { Avatar, Box, Checkbox, IconButton, Tooltip } from "@mui/material";
import { EmojiEmotionsOutlined } from "@mui/icons-material";

const SingleMessageTemplate = memo(
  ({
    message,
    userConfig,
    onMessagesContext,
    onEmojiReplyIconClicked,
    handleMediaPreview,
    templatesList,
    funnerUsers,
    isFunnerAvatarVisible,
    chatCustomizationSettings,
    forwardMessage,
    toggleMessageInForwardList,
    disableInteractions,
  }) => {
    const {
      Message: text,
      Platform,
      FileType,
      Type: sentOrRecieved,
      Id,
      Reply: reply,
      // GlobalUserName,
      GlobalUserId,
      reactions,
      DateCreate: timeFromServer,
      Status: status,
      StatusHeb: statusHeb,
      File: file,
      IsForwarded,
      WebsitePreviewObj,
      Object,
    } = message;

    let initials = null;
    let statusImg = null;
    let fileTypeImg = "";
    let messageInfo = null;
    let reactionsList = null;
    const isDisplayAvatar = userConfig.isDisplayName;

    const mediaTypes = ["document", "docx", "image", "pdf", "xlsx", "zip"];
    const classByMediaType = {
      audio: "message-with-upload_audio",
      contacts: "message-with-upload_contact",
      location: "message-with-upload_location",
      video: "message-with-upload_video",
    };
    const isLTRState =
      userConfig.isMssgRTL === false || userConfig.isMssgRTL === "false";

    const audioDurationRef = useRef(null);
    const audioTrackRef = useRef(null);
    const videoTrackRef = useRef(null);
    const messageRef = useRef(null);

    const messageAssignee = funnerUsers?.find(
      (funnerUser) => funnerUser.Udid === GlobalUserId,
    );

    if (messageAssignee) {
      const GlobalUserName = messageAssignee.FullName;
      initials = GlobalUserName.split(" ")
        .map((str) => str.charAt(0))
        .join("");
    }

    if (status && sentOrRecieved === "outcoming") {
      if (status === "read") {
        statusImg = doubleV;
      } else if (status === "sent") {
        statusImg = singleV;
      } else if (status === "delivered") {
        statusImg = doubleVgray;
      } else if (status !== "note") {
        statusImg = errorOrange;
      }
    }

    if (FileType === "document") {
      if (Object) {
        let fileTypeImgCurrent = "";
        const splitFileName = Object.split(".");
        const fileExt = splitFileName[splitFileName.length - 1].toLowerCase();

        if (fileExt === "pdf") {
          fileTypeImgCurrent = uploadPreview;
        } else if (fileExt === "docx") {
          fileTypeImgCurrent = docx;
        } else if (fileExt === "xlsx") {
          fileTypeImgCurrent = xlsx;
        } else if (fileExt === "zip") {
          fileTypeImgCurrent = zip;
        } else if (fileExt === "image" || fileExt.match(/(png|jpe?g|gif)$/i)) {
          fileTypeImgCurrent = Object;
        } else if (fileExt.match(/(mp4|webm|avi|mov|mkv|flv|wmv)$/i)) {
          fileTypeImgCurrent = Object;
        }

        fileTypeImg = fileTypeImgCurrent;
      }
    } else if (mediaTypes.includes(FileType)) {
      let fileTypeImgCurrent = "";

      if (FileType === "pdf") {
        fileTypeImgCurrent = uploadPreview;
      } else if (FileType === "docx") {
        fileTypeImgCurrent = docx;
      } else if (FileType === "xlsx") {
        fileTypeImgCurrent = xlsx;
      } else if (FileType === "zip") {
        fileTypeImgCurrent = zip;
      } else if (FileType === "image" || FileType.match(/(png|jpe?g|gif)$/i)) {
        fileTypeImgCurrent = file;
      } else if (FileType.match(/(mp4|webm|avi|mov|mkv|flv|wmv)$/i)) {
        fileTypeImgCurrent = file;
      }

      fileTypeImg = fileTypeImgCurrent;
    }

    if (FileType === "info") {
      if (Object) {
        const messageInfoObj = IsJsonString(Object)
          ? JSON.parse(Object)
          : Object;
        messageInfo = messageInfoObj;
      }
    }

    if (reactions) {
      reactionsList = JSON.parse(reactions);
    }

    return (
      <Box
        id={`funner-global-chat-msg-${Id}`}
        className={`message-wrap message-with-upload ${
          classByMediaType[FileType] ? classByMediaType[FileType] : ""
        } ${sentOrRecieved === "outcoming" ? "" : "user"} ${
          FileType === "note" ? "note-msg" : ""
        } ${Platform === "fb" ? "fb-msg" : ""} ${
          statusImg === errorOrange ? "error-status-msg" : ""
        }
        ${!userConfig.isDateTime ? "no-date" : ""}
      `}
        sx={{
          pt: isFunnerAvatarVisible ? "18px !important" : "3px !important",
          "&:hover .reaction-emoji": {
            display: "inline-flex",
          },
          pb: 0.375,
          bgcolor:
            forwardMessage.visible &&
            forwardMessage.messagesList.includes(message.Id)
              ? chatCustomizationSettings?.find(
                  (option) => option.type === "conversationBackground",
                )?.value === "image"
                ? `rgba(255, 255, 255, 0.25)`
                : chatCustomizationSettings?.find(
                    (option) => option.type === "conversationBackground",
                  )?.value === "color"
                ? getContrastYIQ(
                    chatCustomizationSettings.find(
                      (option) => option.type === "conversationBackground",
                    ).customColor.background,
                  ) === "white"
                  ? "rgba(255, 255, 255, 0.25)"
                  : "secondary.light"
                : "secondary.light"
              : null,
          "&:hover": forwardMessage.visible &&
            !forwardMessage.messagesList.includes(message.Id) && {
              bgcolor:
                chatCustomizationSettings?.find(
                  (option) => option.type === "conversationBackground",
                )?.value === "image"
                  ? `rgba(255, 255, 255, 0.15)`
                  : chatCustomizationSettings?.find(
                      (option) => option.type === "conversationBackground",
                    )?.value === "color"
                  ? getContrastYIQ(
                      chatCustomizationSettings.find(
                        (option) => option.type === "conversationBackground",
                      ).customColor.background,
                    ) === "white"
                    ? "rgba(255, 255, 255, 0.15)"
                    : "rgba(42, 56, 71, 0.04)"
                  : "rgba(42, 56, 71, 0.04)",
            },
          cursor: forwardMessage.visible && "pointer",
        }}
        onClick={() =>
          forwardMessage.visible ? toggleMessageInForwardList(message.Id) : null
        }
      >
        {forwardMessage.visible && (
          <Checkbox
            checked={forwardMessage.messagesList.includes(message.Id)}
            sx={{ alignSelf: "center" }}
          />
        )}
        {sentOrRecieved === "outcoming" &&
          isDisplayAvatar &&
          (messageAssignee?.Image ? (
            <Tooltip title={messageAssignee.FullName} placement="bottom-end">
              <Avatar
                src={messageAssignee?.Image}
                sx={{
                  width: 32,
                  height: 32,
                  mt: -1.5,
                  visibility: isFunnerAvatarVisible ? "visible" : "hidden",
                }}
              />
            </Tooltip>
          ) : (
            <span
              className={`message-initials__tooltip`}
              style={{
                marginTop: -12,
                visibility: isFunnerAvatarVisible ? "visible" : "hidden",
              }}
            >
              {initials}
              <span className="message-initials__tooltip-hover">
                {messageAssignee?.FullName || ""}
              </span>
            </span>
          ))}

        <Box
          className={`message ${
            FileType === "info"
              ? `message--info message--info-${
                  messageInfo?.infoType || messageInfo?.infofType
                }`
              : ""
          } ${FileType === "video" ? "message-video" : ""} ${
            reactions ? "reactions" : ""
          } ${
            FileType === "template" && IsJsonString(text)
              ? JSON.parse(text)?.BODY?.text?.length >= 400
                ? "large-width"
                : JSON.parse(text)?.BODY?.text?.length >= 200
                ? "medium-width"
                : ""
              : text?.length >= 400
              ? "large-width"
              : text?.length >= 200
              ? "medium-width"
              : ""
          }
        `}
          ref={messageRef}
          data-additional-menu-container
          onContextMenu={(e) =>
            disableInteractions ? null : onMessagesContext(e, message)
          }
          sx={{
            bgcolor:
              chatCustomizationSettings?.find(
                (option) =>
                  option.type ===
                  (FileType === "note"
                    ? null
                    : sentOrRecieved === "outcoming"
                    ? "outcomingMessage"
                    : "incomingMessage"),
              )?.value === "custom"
                ? chatCustomizationSettings?.find(
                    (option) =>
                      option.type ===
                      (sentOrRecieved === "outcoming"
                        ? "outcomingMessage"
                        : "incomingMessage"),
                  ).customColor.background + " !important"
                : null,
            borderColor:
              chatCustomizationSettings?.find(
                (option) =>
                  option.type === (FileType === "note" ? "noteMessage" : null),
              )?.value === "custom"
                ? chatCustomizationSettings?.find(
                    (option) =>
                      option.type ===
                      (FileType === "note" ? "noteMessage" : null),
                  ).customColor.background + " !important"
                : null,

            borderTopRightRadius:
              isFunnerAvatarVisible &&
              ((sentOrRecieved === "outcoming" && !isLTRState) ||
                (sentOrRecieved === "incoming" && isLTRState))
                ? "0 !important"
                : null,
            borderTopLeftRadius:
              isFunnerAvatarVisible &&
              ((sentOrRecieved === "outcoming" && isLTRState) ||
                (sentOrRecieved === "incoming" && !isLTRState))
                ? "0 !important"
                : null,

            "&:before": {
              borderTopColor:
                chatCustomizationSettings?.find(
                  (option) =>
                    option.type ===
                    (sentOrRecieved === "outcoming"
                      ? "outcomingMessage"
                      : "incomingMessage"),
                )?.value === "custom"
                  ? chatCustomizationSettings?.find(
                      (option) =>
                        option.type ===
                        (sentOrRecieved === "outcoming"
                          ? "outcomingMessage"
                          : "incomingMessage"),
                    ).customColor.background + " !important"
                  : null,

              display: isFunnerAvatarVisible ? "block" : "none",
            },
          }}
        >
          {IsForwarded && (
            <div className="message-forwarded">
              <img
                src={forwardedLink}
                className="message-forwarded__curve-arrow"
                alt=""
              />
              <span className="message-forwarded__text">Forwarded</span>
            </div>
          )}

          <ul
            className={`reactions-list ${
              reactionsList?.length > 0 ? "active" : ""
            }`}
          >
            {reactionsList?.map((reaction, index) => (
              <li key={reaction?.replace(/"(.*?)"/g, "($1)")}>
                {reaction?.replace(/"(.*?)"/g, "($1)")}
              </li>
            ))}
          </ul>

          {FileType === "audio" && (
            <AudioMessage
              Id={Id}
              file={file}
              Reply={reply}
              audioDurationRef={audioDurationRef}
              audioTrackRef={audioTrackRef}
            />
          )}
          {FileType === "video" && (
            <VideoMessage file={file} Id={Id} videoTrackRef={videoTrackRef} />
          )}

          {FileType === "catalog" && (
            <div className="message-indicator message-indicator--catalog">
              Catalog
            </div>
          )}
          {FileType === "order" && (
            <div className="message-indicator message-indicator--order">
              Order
            </div>
          )}
          {FileType === "contacts" && <ContactsMessage object={Object} />}
          {FileType === "location" && <LocationMessage Object={Object} />}
          {FileType === "note" && (
            <NoteMessage
              GlobalUserName={messageAssignee?.FullName || ""}
              chatCustomizationSettings={chatCustomizationSettings}
            />
          )}
          {FileType === "reply_button" && (
            <ReplyButtonMessage replyObject={reply} />
          )}

          {mediaTypes.includes(FileType) && (
            <div className="message__upload-container">
              <div
                className={`message__upload-preview ${
                  FileType !== "image" ? "document" : ""
                }`}
              >
                <img src={fileTypeImg} alt="" />
                {FileType === "image" || FileType === "pdf" ? (
                  <button
                    className="message__upload-preview-link"
                    style={{ cursor: "pointer" }}
                    type="button"
                    onClick={() => {
                      handleMediaPreview({
                        visible: true,
                        file: file,
                        type: FileType,
                      });
                    }}
                  >
                    <div className="funner-icon">
                      <svg
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                        <g
                          id="SVGRepo_tracerCarrier"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></g>
                        <g id="SVGRepo_iconCarrier">
                          {" "}
                          <g id="Interface / Magnifying_Glass_Plus">
                            {" "}
                            <path
                              id="Vector"
                              d="M7 10H10M10 10H13M10 10V7M10 10V13M15 15L21 21M10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10C17 13.866 13.866 17 10 17Z"
                              stroke="#ffffff"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>{" "}
                          </g>{" "}
                        </g>
                      </svg>
                    </div>
                  </button>
                ) : (
                  <a
                    href={file}
                    target="_blank"
                    className="message__upload-preview-link"
                    rel="noreferrer"
                  >
                    <div className="funner-icon">
                      <svg
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                        <g
                          id="SVGRepo_tracerCarrier"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></g>
                        <g id="SVGRepo_iconCarrier">
                          {" "}
                          <g id="Interface / Magnifying_Glass_Plus">
                            {" "}
                            <path
                              id="Vector"
                              d="M7 10H10M10 10H13M10 10V7M10 10V13M15 15L21 21M10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10C17 13.866 13.866 17 10 17Z"
                              stroke="#ffffff"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>{" "}
                          </g>{" "}
                        </g>
                      </svg>
                    </div>
                  </a>
                )}
              </div>
              {/* {FileType !== "image" && (
              <p className="message__upload-file-name">
                <a
                  target="_blank"
                  href={FileType === "document" ? Object : file}
                  rel="noreferrer"
                >
                  פתח קובץ
                </a>
                <span className="demo-mode__text display-none">Demo mode</span>
              </p>
            )} */}
            </div>
          )}

          {FileType === "template" ? (
            <TemplateMessage
              text={text}
              Id={Id}
              statusImg={statusImg}
              sentOrRecieved={sentOrRecieved}
              status={status}
              statusHeb={statusHeb}
              isDateTime={userConfig.isDateTime}
              timeFromServer={timeFromServer}
              templatesList={templatesList}
              handleMediaPreview={handleMediaPreview}
              chatCustomizationSettings={chatCustomizationSettings}
            />
          ) : (
            <>
              <Box
                className="whatsapp regular-text"
                sx={{
                  color:
                    chatCustomizationSettings?.find(
                      (option) =>
                        option.type ===
                        (FileType === "note"
                          ? "noteMessage"
                          : sentOrRecieved === "outcoming"
                          ? "outcomingMessage"
                          : "incomingMessage"),
                    )?.value === "custom"
                      ? chatCustomizationSettings?.find(
                          (option) =>
                            option.type ===
                            (FileType === "note"
                              ? "noteMessage"
                              : sentOrRecieved === "outcoming"
                              ? "outcomingMessage"
                              : "incomingMessage"),
                        ).customColor.text + " !important"
                      : null,
                }}
              >
                <LinkifiedText
                  text={text}
                  websitePreviewObj={WebsitePreviewObj}
                  funnerUsers={funnerUsers}
                  messageType={FileType}
                  chatCustomizationSettings={chatCustomizationSettings}
                />
                {FileType === "order" && <OrderMessage Object={Object} />}
              </Box>

              <div className="d-flex message-bottom">
                {
                  // statusImg === errorOrange &&
                  sentOrRecieved === "outcoming" && FileType !== "note" && (
                    <Tooltip title={statusHeb || status}>
                      <img
                        width="16"
                        className={`status-img ${
                          !statusImg ? "display-none" : ""
                        }`}
                        src={statusImg}
                        alt=""
                      />
                      {/* <div className="whatsapp-status-tooltip">
                        <div className="whatsapp-status-tooltip__content">
                          <p>{statusHeb || status}</p>
                        </div>
                      </div> */}
                    </Tooltip>
                  )
                }
                {FileType === "audio" && (
                  <span
                    className={`audio-message__time-playing funner-global-audio-message__time-playing-${Id}`}
                    ref={audioTrackRef}
                  ></span>
                )}
                {FileType === "video" && (
                  <span
                    className={`video-message__time-playing funner-global-video-message__time-playing-${Id}`}
                  ></span>
                )}
                <Box
                  component="span"
                  className={`text-small text-muted ${
                    userConfig.isDateTime ? "active" : ""
                  }`}
                  sx={{
                    color:
                      chatCustomizationSettings?.find(
                        (option) =>
                          option.type ===
                          (FileType === "note"
                            ? "noteMessage"
                            : sentOrRecieved === "outcoming"
                            ? "outcomingMessage"
                            : "incomingMessage"),
                      )?.value === "custom"
                        ? chatCustomizationSettings?.find(
                            (option) =>
                              option.type ===
                              (FileType === "note"
                                ? "noteMessage"
                                : sentOrRecieved === "outcoming"
                                ? "outcomingMessage"
                                : "incomingMessage"),
                          ).customColor.date + " !important"
                        : "rgba(0, 0, 0, 0.38)",
                  }}
                >
                  {timeFromServer}
                </Box>
              </div>
            </>
          )}

          {FileType === "buttons" && (
            <ButtonsMessage
              Object={Object}
              FileType={FileType}
              chatCustomizationSettings={chatCustomizationSettings}
              sentOrRecieved={sentOrRecieved}
            />
          )}

          {Platform === "fb" && (
            <div className="facebook-message-indicator">
              <img src={facebookRounded} alt="" />
            </div>
          )}
        </Box>
        {!disableInteractions && (
          <IconButton
            sx={{
              display: "none",
              alignSelf: "flex-start",
            }}
            className="reaction-emoji"
            onClick={(e) => onEmojiReplyIconClicked(e, message)}
          >
            <EmojiEmotionsOutlined />
          </IconButton>
        )}
      </Box>
    );
  },
  (prevProps, nextProps) => {
    return (
      deepEqual(prevProps.message, nextProps.message) &&
      deepEqual(prevProps.userConfig, nextProps.userConfig) &&
      deepEqual(prevProps.selectedChatUser, nextProps.selectedChatUser) &&
      deepEqual(prevProps.templatesList, nextProps.templatesList) &&
      deepEqual(prevProps.funnerUsers, nextProps.funnerUsers) &&
      deepEqual(prevProps.forwardMessage, nextProps.forwardMessage) &&
      deepEqual(
        prevProps.chatCustomizationSettings,
        nextProps.chatCustomizationSettings,
      ) &&
      prevProps.isFunnerAvatarVisible === nextProps.isFunnerAvatarVisible &&
      prevProps.disableInteractions === nextProps.disableInteractions
    );
  },
);

export default SingleMessageTemplate;
