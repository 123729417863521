import React from "react";
import {
  doubleV,
  doubleVgray,
  errorOrange,
  facebookRounded,
  singleV,
} from "../../../libs/images";
import ReplyMediaTemplate from "./ReplyMessageTypes/ReplyMediaTemplate";
import { IsJsonString, getContrastYIQ } from "../../../libs/helpers";
import { Avatar, Box, Checkbox, IconButton, Tooltip } from "@mui/material";
import { EmojiEmotionsOutlined } from "@mui/icons-material";

function SingleMessageReplyTemplate({
  message,
  userConfig,
  onMessagesContext,
  onEmojiReplyIconClicked,
  scrollToReplyMessage,
  templatesList,
  funnerUsers,
  isFunnerAvatarVisible,
  chatUsersGlobalRef,
  chatCustomizationSettings,
  forwardMessage,
  toggleMessageInForwardList,
  disableInteractions,
}) {
  const {
    Message: text,
    Platform,
    FileType,
    Type: sentOrRecieved,
    Id,
    Reply: reply,
    GlobalUserId,
    reactions,
    DateCreate: timeFromServer,
    Status: status,
    StatusHeb: statusHeb,
  } = message;

  let initials = null;
  let statusImg = null;
  let reactionsList = null;
  const isDisplayAvatar = userConfig.isDisplayName;
  const isLTRState =
    userConfig.isMssgRTL === false || userConfig.isMssgRTL === "false";

  const messageAssignee = funnerUsers?.find(
    (pwrlnkUser) => pwrlnkUser.Udid === GlobalUserId,
  );

  if (messageAssignee) {
    const GlobalUserName = messageAssignee.FullName;
    initials = GlobalUserName.split(" ")
      .map((str) => str.charAt(0))
      .join("");
  }

  if (status && sentOrRecieved === "outcoming") {
    if (status === "read") {
      statusImg = doubleV;
    } else if (status === "sent") {
      statusImg = singleV;
    } else if (status === "delivered") {
      statusImg = doubleVgray;
    } else if (status !== "note") {
      statusImg = errorOrange;
    }
  }

  if (reactions) {
    reactionsList = JSON.parse(reactions);
  }

  return (
    <Box
      id={`funner-global-chat-msg-${Id}`}
      className={`d-flex message-wrap mb-4 
    ${sentOrRecieved === "outcoming" ? "" : "user"} ${
        status === "note" ? "note-msg" : ""
      } ${Platform === "fb" ? "fb-msg" : ""} ${
        statusImg === errorOrange ? "error-status-msg" : ""
      } ${!userConfig.isDateTime ? "no-date" : ""}`}
      onContextMenu={(e) =>
        disableInteractions ? null : onMessagesContext(e, message)
      }
      reply-message_contact={""}
      reply-message={""}
      reply={""}
      sx={{
        pt: isFunnerAvatarVisible ? "18px !important" : "3px !important",
        "&:hover .reaction-emoji": {
          display: "inline-flex",
        },
        pb: 0.375,
        bgcolor:
          forwardMessage.visible &&
          forwardMessage.messagesList.includes(message.Id)
            ? chatCustomizationSettings?.find(
                (option) => option.type === "conversationBackground",
              )?.value === "image"
              ? `rgba(255, 255, 255, 0.25)`
              : chatCustomizationSettings?.find(
                  (option) => option.type === "conversationBackground",
                )?.value === "color"
              ? getContrastYIQ(
                  chatCustomizationSettings.find(
                    (option) => option.type === "conversationBackground",
                  ).customColor.background,
                ) === "white"
                ? "rgba(255, 255, 255, 0.25)"
                : "secondary.light"
              : "secondary.light"
            : null,
        "&:hover": forwardMessage.visible &&
          !forwardMessage.messagesList.includes(message.Id) && {
            bgcolor:
              chatCustomizationSettings?.find(
                (option) => option.type === "conversationBackground",
              )?.value === "image"
                ? `rgba(255, 255, 255, 0.15)`
                : chatCustomizationSettings?.find(
                    (option) => option.type === "conversationBackground",
                  )?.value === "color"
                ? getContrastYIQ(
                    chatCustomizationSettings.find(
                      (option) => option.type === "conversationBackground",
                    ).customColor.background,
                  ) === "white"
                  ? "rgba(255, 255, 255, 0.15)"
                  : "rgba(42, 56, 71, 0.04)"
                : "rgba(42, 56, 71, 0.04)",
          },
        cursor: forwardMessage.visible && "pointer",
      }}
      onClick={() =>
        forwardMessage.visible ? toggleMessageInForwardList(message.Id) : null
      }
    >
      {forwardMessage.visible && (
        <Checkbox
          checked={forwardMessage.messagesList.includes(message.Id)}
          sx={{ alignSelf: "center" }}
        />
      )}
      {sentOrRecieved === "outcoming" &&
        isDisplayAvatar &&
        (messageAssignee?.Image ? (
          <Tooltip title={messageAssignee.FullName} placement="bottom-end">
            <Avatar
              src={messageAssignee?.Image}
              sx={{
                width: 32,
                height: 32,
                mt: -1.5,
                visibility: isFunnerAvatarVisible ? "visible" : "hidden",
              }}
            />
          </Tooltip>
        ) : (
          <span
            className={`message-initials__tooltip`}
            style={{
              marginTop: -12,
              visibility: isFunnerAvatarVisible ? "visible" : "hidden",
            }}
          >
            {initials}
            <span className="message-initials__tooltip-hover">
              {messageAssignee?.FullName || ""}
            </span>
          </span>
        ))}

      <Box
        className={`
          message flex-grow-1
          ${reactions ? "reactions" : ""}
          ${
            FileType === "template" && IsJsonString(text)
              ? JSON.parse(text)?.BODY?.text?.length >= 400
                ? "large-width"
                : JSON.parse(text)?.BODY?.text?.length >= 200
                ? "medium-width"
                : ""
              : text?.length >= 400
              ? "large-width"
              : text?.length >= 200
              ? "medium-width"
              : ""
          }
          `}
        data-additional-menu-container
        sx={{
          bgcolor:
            chatCustomizationSettings?.find(
              (option) =>
                option.type ===
                (FileType === "note"
                  ? null
                  : sentOrRecieved === "outcoming"
                  ? "outcomingMessage"
                  : "incomingMessage"),
            )?.value === "custom"
              ? chatCustomizationSettings?.find(
                  (option) =>
                    option.type ===
                    (sentOrRecieved === "outcoming"
                      ? "outcomingMessage"
                      : "incomingMessage"),
                ).customColor.background + " !important"
              : null,
          borderColor:
            chatCustomizationSettings?.find(
              (option) =>
                option.type === (FileType === "note" ? "noteMessage" : null),
            )?.value === "custom"
              ? chatCustomizationSettings?.find(
                  (option) =>
                    option.type ===
                    (FileType === "note" ? "noteMessage" : null),
                ).customColor.background + " !important"
              : null,

          "&:before": {
            borderTopColor:
              chatCustomizationSettings?.find(
                (option) =>
                  option.type ===
                  (sentOrRecieved === "outcoming"
                    ? "outcomingMessage"
                    : "incomingMessage"),
              )?.value === "custom"
                ? chatCustomizationSettings?.find(
                    (option) =>
                      option.type ===
                      (sentOrRecieved === "outcoming"
                        ? "outcomingMessage"
                        : "incomingMessage"),
                  ).customColor.background + " !important"
                : null,

            display: isFunnerAvatarVisible ? "block" : "none",
          },

          borderTopRightRadius:
            isFunnerAvatarVisible &&
            ((sentOrRecieved === "outcoming" && !isLTRState) ||
              (sentOrRecieved === "incoming" && isLTRState))
              ? "0 !important"
              : null,
          borderTopLeftRadius:
            isFunnerAvatarVisible &&
            ((sentOrRecieved === "outcoming" && isLTRState) ||
              (sentOrRecieved === "incoming" && !isLTRState))
              ? "0 !important"
              : null,
        }}
      >
        <ul
          className={`reactions-list ${
            reactionsList?.length > 0 ? "active" : ""
          }`}
        >
          {reactionsList?.map((reaction, index) => (
            <li key={reaction?.replace(/"(.*?)"/g, "($1)")}>
              {reaction?.replace(/"(.*?)"/g, "($1)")}
            </li>
          ))}
        </ul>

        {/* <div
          className="smile-to-react"
          onClick={(e) => onEmojiReplyIconClicked(e, message)}
        >
          <img
            width="20"
            height="20"
            src={smileFace}
            alt=""
            className="toggle-reaction-list"
          />
        </div> */}
        {/* <IconButton
          sx={{
            position: "absolute",
            display: "none",
            left:
              (sentOrRecieved === "outcoming" &&
                !(
                  userConfig.isMssgRTL === false ||
                  userConfig.isMssgRTL === "false"
                )) ||
              (sentOrRecieved === "incoming" &&
                (userConfig.isMssgRTL === false ||
                  userConfig.isMssgRTL === "false"))
                ? -42
                : "auto",
            right:
              (sentOrRecieved === "outcoming" &&
                !(
                  userConfig.isMssgRTL === false ||
                  userConfig.isMssgRTL === "false"
                )) ||
              (sentOrRecieved === "incoming" &&
                (userConfig.isMssgRTL === false ||
                  userConfig.isMssgRTL === "false"))
                ? "auto"
                : -42,
            top: -4,
          }}
          className="reaction-emoji"
          onClick={(e) => onEmojiReplyIconClicked(e, message)}
        >
          <EmojiEmotionsOutlined />
        </IconButton> */}
        <div
          className={`message__upload-container ${
            reply.FileType === "contacts"
              ? "message__upload-container_reply-contact"
              : ""
          }`}
        >
          {message.Reply?.Id === 2480720 && console.log(message)}
          <ReplyMediaTemplate
            replyObject={reply}
            Id={Id}
            scrollToReplyMessage={scrollToReplyMessage}
            templatesList={templatesList}
            chatUsersGlobalRef={chatUsersGlobalRef}
          />
        </div>
        <Box
          className="whatsapp regular-text"
          sx={{
            color:
              chatCustomizationSettings?.find(
                (option) =>
                  option.type ===
                  (FileType === "note"
                    ? "noteMessage"
                    : sentOrRecieved === "outcoming"
                    ? "outcomingMessage"
                    : "incomingMessage"),
              )?.value === "custom"
                ? chatCustomizationSettings?.find(
                    (option) =>
                      option.type ===
                      (FileType === "note"
                        ? "noteMessage"
                        : sentOrRecieved === "outcoming"
                        ? "outcomingMessage"
                        : "incomingMessage"),
                  ).customColor.text + " !important"
                : null,
          }}
        >
          {text ? text : ""}
        </Box>
        <div className="d-flex message-bottom">
          {
            // statusImg === errorOrange &&
            sentOrRecieved === "outcoming" && (
              <Tooltip title={statusHeb || status}>
                <img
                  width="16"
                  className={`status-img ${
                    statusImg === null ? "display-none" : ""
                  }`}
                  src={statusImg}
                  alt=""
                />
              </Tooltip>
              // <div className="whatsapp-status-tooltip">
              //   <div className="whatsapp-status-tooltip__content">
              //     <p>{statusHeb || status}</p>
              //   </div>
              // </div>
            )
          }
          <Box
            component={"span"}
            className={`text-small text-muted ${
              userConfig.isDateTime ? "active" : ""
            }`}
            sx={{
              color:
                chatCustomizationSettings?.find(
                  (option) =>
                    option.type ===
                    (FileType === "note"
                      ? "noteMessage"
                      : sentOrRecieved === "outcoming"
                      ? "outcomingMessage"
                      : "incomingMessage"),
                )?.value === "custom"
                  ? chatCustomizationSettings?.find(
                      (option) =>
                        option.type ===
                        (FileType === "note"
                          ? "noteMessage"
                          : sentOrRecieved === "outcoming"
                          ? "outcomingMessage"
                          : "incomingMessage"),
                    ).customColor.date + " !important"
                  : "rgba(0, 0, 0, 0.38)",
            }}
          >
            {timeFromServer}
          </Box>
        </div>
        {Platform === "fb"
          ? `<div className="facebook-message-indicator"><img src="${facebookRounded}" alt=""></div>`
          : ""}
      </Box>
      {!disableInteractions && (
        <IconButton
          sx={{
            display: "none",
            alignSelf: "flex-start",
          }}
          className="reaction-emoji"
          onClick={(e) => onEmojiReplyIconClicked(e, message)}
        >
          <EmojiEmotionsOutlined />
        </IconButton>
      )}
    </Box>
  );
}

export default SingleMessageReplyTemplate;
