import React, { useEffect, useRef } from "react";
import { convertSecondstoTime } from "../../../../libs/helpers";

function VideoMessage({ file, Id, videoTrackRef }) {
  const videoElementRef = useRef(null);
  const playBtnRef = useRef(null);
  // const videoTrackRef = useRef(null);
  // const videoDurationRef = useRef(null);
  const videoContainerRef = useRef(null);

  function handleVideoControling() {
    const videoElement = videoElementRef.current;
    const playBtn = playBtnRef.current;
    const videoTrackElement = videoTrackRef.current;
    const videoDurationElement = videoTrackRef.current;
    const videoContainer = videoContainerRef.current;

    function updateVideoTrack(e) {
      const time = convertSecondstoTime(e.target.currentTime);
      const duration = convertSecondstoTime(e.target.duration);
      if (videoTrackElement) {
        videoTrackElement.innerHTML = `${time} / ${duration}`;
      }
    }

    function handleVideoDuration() {
      if (videoElement && videoElement.duration) {
        const duration = convertSecondstoTime(
          Math.round(videoElement.duration),
        );

        if (videoDurationElement) {
          videoDurationElement.innerHTML = `0:00 / ${duration}`;
        }
      }
    }

    function handleVideo() {
      if (videoElement.paused) {
        videoElement.play();
        playBtn.classList.add("paused");
        videoContainer.classList.toggle("playing");
        videoContainer.classList.toggle("playing-now");
      } else {
        videoElement.pause();
        playBtn.classList.remove("paused");
        videoContainer.classList.toggle("playing");
        videoContainer.classList.toggle("playing-now");
      }
    }

    function handleVideoEnded() {
      videoElement.currentTime = 0; // Reset the video to the beginning
      playBtn.classList.remove("paused");
    }

    // Add the 'ended' event listener to the video element
    videoElement.addEventListener("ended", () => handleVideoEnded());

    videoElement.addEventListener("timeupdate", (event) => {
      updateVideoTrack(event);
    });

    videoElement.addEventListener("loadedmetadata", () => {
      handleVideoDuration();
    });

    playBtn.addEventListener("click", () => {
      handleVideo();
    });
  }

  useEffect(() => {
    handleVideoControling();
  }, []);

  return (
    <div
      className={`message__upload-container message__upload-container_video funner-global-message__upload-container-wrap_video-${Id} playing`}
      ref={videoContainerRef}
    >
      <div className="message__video video-message">
        <div className="video-message__container">
          <video
            className={`video-message__main-video funner-global-video-message__main-video-${Id}`}
            ref={videoElementRef}
            src={file}
          ></video>
          <div
            className={`video-message__play-container video-message__play-container-${Id}`}
          >
            <button
              className={`video-message__play-btn funner-global-video-message__play-btn-${Id}`}
              ref={playBtnRef}
            ></button>
          </div>
        </div>
        {/* <a
          href={file}
          target="_blank"
          className="video-message__link"
          rel="noreferrer"
        >
          פתח קובץ
        </a> */}
      </div>
    </div>
  );
}

export default VideoMessage;
